import {
  AdProvider as CommonAdProvider,
  adsHelper
} from '@dmm/react-common-components';
import * as utils from '../../../store/utils';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class AdProvider extends PureComponent {
  constructor(props) {
    super(props);

    if (!utils.isServer()) {
      this.noAdsParam = adsHelper.hasNoAdsParam();
    }
  }

  registerAdsCallback = (callback) => {
    this.adsCallback = callback;
  };

  componentDidUpdate() {
    if (!this.url) {
      this.url = this.props.url;
      return;
    }

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      if (this.props.url !== this.url && !this.props.isWorking) {
        this.url = this.props.url;
        this.adsCallback();
      }
    }, 0);
  }

  render() {
    return (
      <CommonAdProvider
        noAdsParam={this.noAdsParam}
        adsConfig={this.props.adsConfig}
        targeting={this.props.targeting}
        refreshSubscribe={this.registerAdsCallback}
      >
        {this.props.children}
      </CommonAdProvider>
    );
  }
}

AdProvider.propTypes = {
  adsConfig: PropTypes.object,
  children: PropTypes.node,
  isWorking: PropTypes.bool,
  targeting: PropTypes.object,
  url: PropTypes.string
};
