import React, {useContext} from 'react';
import Schema from '../index';
import {getHomeOrganizationSchema, getHomeWebsiteSchema} from '../../../utils/metaContentHelper';
import {getCurrentLocale, LanguageContext} from '../../../utils/language';
import {PortalConfigContext} from '../../../config/portal';
import PropTypes from 'prop-types';

const homeSchemaProps = (config, langContext, getLocale) => {
  const {host} = langContext;
  const homeWebsiteSchema = getHomeWebsiteSchema(host, config, getLocale);
  const homeOrganizationSchema = getHomeOrganizationSchema(host, config, getLocale);
  return {homeWebsiteSchema, homeOrganizationSchema};
};

const HomeSchema = ({getLocale = getCurrentLocale}) => {
  const langContext = useContext(LanguageContext);
  const config = useContext(PortalConfigContext);
  const {homeWebsiteSchema, homeOrganizationSchema} = homeSchemaProps(config, langContext, getLocale);
  if (!homeWebsiteSchema || !homeOrganizationSchema) {
    return null;
  }

  return (
    <>
      <Schema data={homeOrganizationSchema} />
      <Schema data={homeWebsiteSchema} />
    </>
  );
};

HomeSchema.propTypes = {
  getLocale: PropTypes.func
};

export default HomeSchema;
