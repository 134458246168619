import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { PortalConfigContext } from '../../config/portal';
import { generateBlogPath } from '../../utils/urlHelpers/blog';
import { addNotModifiedResizeParams } from '../../utils/listingHelper';
import ImageWithErrorHandler from '../ImageWithErrorHandler';

import classnames from 'classnames';
import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const getArticleURL = (article) => generateBlogPath({}, {article: article.name});

const getAppImageUrl = (url, blogId, imgHost) => {
  if (!blogId) {
    return url;
  }

  const urlString = String(url);
  return imgHost + '/resize/wp/' + blogId + urlString.substring(urlString.indexOf('/files/'));
};

const getArticleFeaturedImageURL = (article) => {
  const baseURL = get(article.content, 'featuredImage', '');
  const parsedURL = baseURL.replace('{{baseUrl}}',article.blogUrl);
  return parsedURL;
};

const getImageParams = (article, imgHost) => {
  const imageUrl = getArticleFeaturedImageURL(article);

  const blogId = article.blogId;

  return {
    src: addNotModifiedResizeParams(getAppImageUrl(imageUrl, blogId, imgHost), {width: 300, height: 200}),
  };
};

const renderFeaturedImage = (article, imgHost) => {
  const imageUrl = getArticleFeaturedImageURL(article);

  if (!imageUrl) {
    return <div className="no-image" />;
  }

  return (
    <ImageWithErrorHandler
      className="article-picture"
      alt={ article.title }
      defaultSrc={ imageUrl }
      { ...getImageParams(article, imgHost) }
    />
  );
};

const toTitleCase = (string) => {
  return string.split(' ').map((word) => {
    return (capitalize(word));
  }).join(' ');
};

const HomeBlogSection = (props) => {
  const {
    blogSection: { records },
    intl: { formatMessage: t },
    featuredArticlesRow = false
  } = props;
  const messages = getMessages();
  const articles = [...records];
  let mainArticle, mainArticleCategory;
  if ( !featuredArticlesRow ){
    mainArticle = articles.shift();
    mainArticleCategory = mainArticle.categories[0];
  }

  const context = useContext(PortalConfigContext);
  const portal = get(context, 'fullName', undefined);
  const imgHost = get(context, 'client.imageserver.hostname');

  return (
    <div className="home-blog-section">
      <h2 className={classnames('blog-section-title', {'row-layout': featuredArticlesRow})}>
        {t(messages.homeBlogSection.title)
          .replace('{portal}', portal)}
      </h2>
      <div className={classnames('home-articles-container', {'row-layout': featuredArticlesRow})}>
        { !featuredArticlesRow &&
          <div className="home-main-article">
            <a href={getArticleURL(mainArticle)}>
              <img src={getArticleFeaturedImageURL(mainArticle)} className="article-picture" alt={mainArticle.title} loading="lazy" />
              <div className="article-text-container">
                <div className="article-title">{mainArticle.title}</div>
                <div className="article-category">{toTitleCase(t(messages.blog.categories[mainArticleCategory]))}</div>
              </div>
            </a>
          </div>
        }
        <div className="home-secondary-articles">
          {articles.map((article, index) => {
            const category = article.categories[0];
            const mainCategory = article.mainCategory;
            const articleExcerpt = get(article, 'content.excerpt', '');
            const titleCategory = mainCategory && messages?.blog?.categories[mainCategory] ?
              toTitleCase(t(messages.blog.categories[mainCategory])) :
              category && messages?.blog?.categories[category] ?
                toTitleCase(t(messages.blog.categories[category])) :
                '';
            return (
              <article key={index} onClick={() => window.location.assign(getArticleURL(article))}>
                <div className="home-article">
                  { renderFeaturedImage(article, imgHost) }
                  { featuredArticlesRow ?
                    <div className="article-text-container">
                      <div className="article-category">{titleCategory}</div>
                      <div className="article-title"><a href={getArticleURL(article)} key={article.id} title={article.title}>{article.title}</a></div>
                      <div className="article-excerpt">{articleExcerpt}</div>
                    </div>
                    :
                    <div className="article-text-container">
                      <div className="article-title"><a href={getArticleURL(article)} key={article.id} title={article.title}>{article.title}</a></div>
                      <div className="article-category">{titleCategory}</div>
                    </div>
                  }
                </div>
              </article>
            );
          })}
        </div>
      </div>
      <div className={classnames('blog-section-read-more', {'row-layout': featuredArticlesRow})}>
        <a href={generateBlogPath({},{})} >
          {t(messages.homeBlogSection.readMore)
            .replace('{portal}', portal)}
        </a>
      </div>
    </div>
  );
};


HomeBlogSection.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  blogSection: PropTypes.shape({
    count: PropTypes.number.isRequired,
    records: PropTypes.array.isRequired,
  }),
  featuredArticlesRow: PropTypes.bool
};


export default connect(
  null,
  (dispatch) => bindActionCreators({}, dispatch)
)(injectIntl(HomeBlogSection));
