import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from '../Input';
import { isOuterClick } from '../../utils';
import './styles.css';

const AUTOCOMPLETE_THRESHOLD = 3;

class InputAutocomplete extends PureComponent {
  state = {
    error: false,
    open: this.props.startOpened,
    selectedOption: {}
  };

  containerRef = React.createRef();

  onClick = (option) => {
    const { onOptionClick } = this.props;
    this.setState({
      open: false,
      textValue: option.label,
      selectedOption: option,
      noOptionSelected: false,
      error: false
    });
    onOptionClick(option);
  };

  onTextChange = (value) => {
    const { defaultDropdownMessage, setValue } = this.props;
    const isGreaterThanThreshold = value.length >= AUTOCOMPLETE_THRESHOLD;
    if (defaultDropdownMessage) {
      this.setState({ noOptionSelected: !!value });
    } else {
      this.setState({ open: isGreaterThanThreshold });
    }
    setValue(value);
  };

  getAutoCompleteOptions = () => {
    const { defaultDropdownMessage, options } = this.props;
    const {
      open,
      selectedOption: { value }
    } = this.state;
    if (defaultDropdownMessage && options.length === 0 && open) {
      return (
        <div id="autocomplete-options" className="autocomplete-options">
          <button disabled className="autocomplete-option-button">
            {defaultDropdownMessage}
          </button>
        </div>
      );
    }

    if (!options.length || !open) {
      return '';
    }

    return (
      <div id="autocomplete-options" className="autocomplete-options">
        {options.map((option, index) => (
          <button
            className={
              `${option.style} ` +
              classnames('autocomplete-option-button', {
                'selected': option.value === value
              })
            }
            key={index}
            onClick={() => this.onClick(option)}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  };

  render() {
    const { className, label, message, value } = this.props;
    const { error } = this.state;

    return (
      <div
        ref={this.containerRef}
        className={classnames('input-autocomplete-container', {
          [className]: Boolean(className)
        })}
      >
        <Input
          label={label}
          value={value}
          setValue={this.onTextChange}
          autoComplete={false}
          {...(error ? { message: message, error: error } : {})}
        />
        {this.getAutoCompleteOptions()}
      </div>
    );
  }

  handleDocumentEvent = (e) => {
    const { onErrorUpdate } = this.props;
    const outerClicked = isOuterClick(e.target, this.containerRef.current);
    this.setState({ open: !outerClicked, error: this.state.noOptionSelected });
    onErrorUpdate(this.state.noOptionSelected);
  };

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentEvent);
    document.addEventListener('keydown', this.handleDocumentEvent);
  }

  componentDidUpdate() {
    const {
      selectedOption: { value }
    } = this.state;
    const { options } = this.props;
    const foundSelectedOption = options.find(
      (options) => options.value === value
    );
    if (!foundSelectedOption && value) {
      this.setState({ selectedOption: {} });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentEvent);
    document.removeEventListener('keydown', this.handleDocumentEvent);
  }
}

InputAutocomplete.propTypes = {
  className: PropTypes.string,
  defaultDropdownMessage: PropTypes.string,
  message: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  onErrorUpdate: PropTypes.func,
  onOptionClick: PropTypes.func,
  setValue: PropTypes.func,
  startOpened: PropTypes.bool,
  value: PropTypes.string
};

InputAutocomplete.defaultProps = {
  className: '',
  options: [],
  onOptionClick: () => {},
  setValue: () => {},
  startOpened: false,
  value: ''
};

export default InputAutocomplete;
