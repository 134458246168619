import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addLead } from '../../store/actions/dataLayer';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PortalConfigContext } from '../../config/portal';
import { getAssetUrl } from '../../utils/commonHelper';
import { getPortalName } from '../../utils/language';

import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

class SocialMedia extends PureComponent {

  createSocialLink(props, t, name, img, alt) {
    const {href, ...newProps} = props;
    const messages = getMessages();
    const socialNetwork = newProps['aria-label'].toLowerCase();
    const id = `${socialNetwork}_link`;
    // key for getConnectionClickData switch
    const leadType = `${socialNetwork} share`;
    newProps.href = get(messages, `footer.socialLinks.${href}`) ?
      t(get(messages, `footer.socialLinks.${href}`)) : href;
    return <a key={name} id={id} {...newProps} onClick={() => this.props.addLead('', leadType)}>
      <LazyLoadImage src={getAssetUrl(img)} alt={alt}/>
    </a>;
  }

  render() {
    const { appStoreLinks, intl: { formatMessage: t }, socialLinks } = this.props;
    const messages = getMessages();
    const context = get(this, 'context', {});
    const isAppBannerDisabled = !!this.context?.supports?.isAppBannerDisabled;
    return (
      <div className="social-wrapper">
        <div className={classnames('social-elements', { media: !appStoreLinks })}>
          <div className={classnames('social-media', { 'social-media-center': isAppBannerDisabled })}>
            <p>{t(get(messages, 'home.connect'))}</p>
            <div className="social-links">
              {
                socialLinks.map(({props, name, img, alt}) => this.createSocialLink(props, t, name, img, alt))
              }
            </div>
          </div>
          { !isAppBannerDisabled && appStoreLinks &&
            <>
              <div className="separator"/>
              <div className="social-apps">
                <p>{t(get(messages, 'home.download'), { portal: getPortalName(context) })}</p>
                <div className="app-links">
                  {appStoreLinks.android &&
                  <a href={appStoreLinks.android}
                    id="android_link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => this.props.addLead('', 'google_play share')}
                    className="android"
                    title="Get it on Google Play"/>
                  }
                  {appStoreLinks.iphone &&
                  <a href={appStoreLinks.iphone}
                    id="ios_link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => this.props.addLead('', 'app_store share')}
                    className="apple"
                    title="Download on the App Store"/>
                  }
                </div>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

SocialMedia.propTypes = {
  appStoreLinks: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  socialLinks: PropTypes.array,
  addLead: PropTypes.func.isRequired,
};

SocialMedia.contextType = PortalConfigContext;

export default connect(
  null,
  (dispatch) => bindActionCreators({addLead}, dispatch)
)(injectIntl(SocialMedia));
