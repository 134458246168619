import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const DefaultImage = ({ size, withText, intl: { formatMessage: t } }) => {
  const messages = getMessages();
  return (
    <div
      className="default-image-wrapper"
      role="img"
      aria-label={t(messages.defaultNotFoundImage)}
    >
      <div className="default-image-centered-container">
        <div
          id="default-image-icon"
          className="default-image-icon"
          style={{ height: `${size}px`, width: `${size}px` }}
        ></div>
        {withText && (
          <div className="default-image-text">
            {t(messages.defaultNotFoundImage)}
          </div>
        )}
      </div>
    </div>
  );
};

DefaultImage.defaultProps = {
  size: '130',
  withText: true
};

DefaultImage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  /** The size in pizels of the icon */
  size: PropTypes.string,
  withText: PropTypes.bool
};

export default injectIntl(DefaultImage);
