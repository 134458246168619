import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import ListingImage from '../ListingImage';
import Link from '../SEO/Link';
import { getConfig, PortalConfigContext } from '../../config/portal';
import { getBoatsConstants } from '../../constants/boats';
import {
  BREAKPOINTS,
  isManufacturerListing,
  getBreakpoint,
  getPriceDrop
} from '../../utils/commonHelper';
import {
  getSellerName,
  getLength,
  currencyFormattingOptions,
  getListingType,
  setImageUrl,
  LOGO_SIZES
} from '../../utils/listingHelper';
import { listingPropTypes } from '../../utils/commonPropTypes';
import { getBoatUrl } from '../../utils/urlHelpers/boat';
import ContactForm from '../ContactForm';
import LiveVideoTourModal from '../LiveVideoTourModal';

import { setListingClick } from '../../store/actions/dataLayer';
import MonthlyPaymentTooltip from '../MonthlyPaymentTooltip';
import './styles.css';
import './card.css';
import { Cookies } from 'react-cookie';
import { PRODUCT_CATEGORY } from '../../constants/dataLayer';
import {getBoatConstantsFromI18n} from '../../tppServices/translations/constants';
import { getMessages } from '../../tppServices/translations/messages';
import ImageWithErrorHandler from '../../components/ImageWithErrorHandler';

class ListingResult extends PureComponent {
  state = {
    contactOpen: false,
    liveVideoContactOpen: false,
    breakpoint: 'desktop'
  };

  displayContactFormData = [];

  getResize() {
    if (this.props.mode === ListingResult.MODES.card) {
      if (this.props.breakpoint === BREAKPOINTS.mobile) {
        return getBoatsConstants().LISTING_RESIZES.cardMobile;
      }
      if (this.props.breakpoint === BREAKPOINTS.tablet) {
        return getBoatsConstants().LISTING_RESIZES.cardTablet;
      }
      return getBoatsConstants().LISTING_RESIZES.cardDesktop;
    }
    if (get(this.props.listing.featureType, 'enhanced', false)) {
      return getBoatsConstants().LISTING_RESIZES.rowEnhanced;
    }
    return getBoatsConstants().LISTING_RESIZES.default;
  }
  resizeHandler = () => {
    this.setState({
      breakpoint: getBreakpoint()
    });
  };

  componentDidMount() {
    const {
      listing,
      position,
      tracking,
      breakpoint,
      listingType: type,
      labels: { getTitle }
    } = this.props;
    const listingType = type || getListingType(listing);
    const { id, model, make } = listing;
    const region = tracking?.trackingInfo?.region;

    tracking.setProductImpression(
      id,
      listingType,
      position.position,
      position.page,
      PRODUCT_CATEGORY.BOAT,
      null,
      region,
      model,
      getTitle(listing),
      make
    );
    if (
      get(getConfig(), 'supports.permutive', false) &&
      get(window, 'permutiveHelper.addListing')
    ) {
      window.permutiveHelper.addListing(listing);
    }
    this.setState({ breakpoint: breakpoint || getBreakpoint() });
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  handleContactButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.displayContactFormData = [];
    if (!this.state.contactOpen) {
      let { listing } = this.props;
      const lengthUOM = get(this.context, 'uom.length.abbr', 'ft');
      const boatConstants = getBoatConstantsFromI18n();
      let contactData = {
        boatClass: listing.class,
        make: listing.make,
        listingType: boatConstants.LISTING_ENHANCED,
        length: `${get(
          listing.specifications.dimensions.lengths.nominal,
          lengthUOM,
          ''
        )}`,
        subdivision: listing.location.address.subdivision
      };
      this.displayContactFormData.push(
        <ContactForm
          key={listing.id}
          open={!this.state.contactOpen}
          itemId={listing.id}
          data={contactData}
          onClose={this.handleContactButtonClose}
          contactSubmitTrack
        />
      );
    }
    this.setState({ contactOpen: !this.state.contactOpen });
  };

  handleContactButtonClose = () => {
    this.setState({ contactOpen: !this.state.contactOpen });
    this.displayContactFormData = [];
  };

  handleLiveVideoTourButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.displayContactFormData = [];
    if (!this.state.liveVideoContactOpen) {
      let { listing } = this.props;
      this.displayContactFormData.push(
        <LiveVideoTourModal
          key={listing.id}
          listingId={listing.id}
          onClose={() => this.handleLiveVideoTourButtonClose()}
        />
      );
    }
    this.setState({ liveVideoContactOpen: !this.state.liveVideoContactOpen });
  };

  handleLiveVideoTourButtonClose = () => {
    this.setState({ liveVideoContactOpen: !this.state.liveVideoContactOpen });
    this.displayContactFormData = [];
  };

  getDealerLogo = () => {
    const { listing } = this.props;
    const logo = (
      get(listing.owner, 'logos.enhanced', '') ||
      get(listing.owner, 'logos.default', '')
    ).replace(/^http:\/\//, 'https://');

    return setImageUrl({
      imageUrl: logo,
      resizerImageParams: {
        width: LOGO_SIZES.listing.w,
        height: LOGO_SIZES.listing.h
      }
    });
  };

  getDealerName = () => {
    const { listing } = this.props;
    return get(listing.owner, 'name', '');
  };

  getFormattingOptions() {
    const currentLocale = get(this.props, 'intl.locale', null);
    const { cookies } = this.props;
    return currencyFormattingOptions(cookies, currentLocale);
  }

  render() {
    const {
      listing,
      position,
      itemCount,
      intl: { formatMessage: t },
      labels,
      mode,
      customUom,
      altText,
      listingType: type
    } = this.props;
    const messages = getMessages();
    const { getPrice, getTitle, getLocation } = labels;
    const listingType = type || getListingType(listing);
    const title = getTitle(listing);

    const priceDisclaimer = get(getConfig(), 'supports.priceDisclaimer', false);
    let price = getPrice(listing);
    if (priceDisclaimer && price) {
      price = price.replace('(', '').replace(')', '') + '*';
    }

    const resize = this.getResize();
    const location = getLocation(listing);
    const listingAnchorParamsDefault = {
      'data-reporting-click-product-id': listing.id,
      'data-reporting-click-listing-type': listingType,
      'data-reporting-rank': position.position,
      'data-reporting-page': position.page
    };
    const listingAnchorParams = {
      ...listingAnchorParamsDefault,
      href: getBoatUrl(listing)
    };

    const seller = getSellerName(listing.owner);
    const enhanced = get(listing.featureType, 'enhanced', false);
    const isManufacturer = isManufacturerListing(listing.isOemModel);
    const hasLiveVideoTour = (listing.attributes || []).includes(
      'LIVE_VIDEO_TOUR'
    );
    const hasVideo = get(listing, 'mediaCount.video', false);
    const hasLocalDelivery = (listing.attributes || []).includes(
      'LOCAL_DELIVERY'
    );
    const has360 = get(listing, 'mediaCount.360Image', false);
    let listingAttribute;
    const bannerAttributes = [
      'AVAILABLE_SOON',
      'IN_STOCK',
      'SALE_PENDING',
      'NEW_ARRIVAL'
    ];
    (listing.attributes || []).forEach((attribute) => {
      if (
        !listingAttribute &&
        bannerAttributes.find((banner) => banner === attribute)
      ) {
        listingAttribute = attribute;
      }
    });

    const length = getLength(listing, undefined, customUom?.length, true);
    const previousPriceObj = get(listing, 'previousPrice', null);
    const formattingOptions = this.getFormattingOptions();
    const currentLocale = get(this.props, 'intl.locale', null);
    const previousPrice = getPriceDrop(
      previousPriceObj,
      get(listing, 'price', null),
      currentLocale,
      formattingOptions
    );
    const monthlyPrice = get(this.props, 'monthlyPrice', null);
    const tridentTeaserRate = get(this.props, 'tridentTeaserRate');
    const isPriceHidden = get(listing, 'price.hidden', false);
    const supportsMonthlyPayment = get(
      this.props,
      'supportsMonthlyPayment',
      false
    );
    const region = this.props.tracking?.trackingInfo?.region;
    const dealerLogo = this.getDealerLogo();

    return (
      <li
        className={classnames(
          'listing-result',
          `listing-result-${mode}`,
          'listingStyleDefault',
          { 'listing-result-enhanced': enhanced },
          { 'isHome': this.props.isHome }
        )}
        data-listing-id={listing.id}
        data-reporting-impression-product-id={listing.id}
        data-reporting-impression-listing-type={listingType}
        onClick={() =>
          setListingClick(listing.id, listingType, region, listing.make)
        }
      >
        {this.displayContactFormData}
        <Link className="inner" {...listingAnchorParams}>
          <div className="image-container">
            <div className="dummy" />
            <div className="image">
              {(enhanced || !isManufacturer) && listingAttribute && (
                <div className={`banner-attribute ${listingAttribute}`}>
                  {t(messages[`${listingAttribute}`])}
                </div>
              )}
              {
                <div className="icons">
                  {get(listing, 'cpybLogo', '') && (
                    <div className="listing-card-image-cpyb">
                      <div className="icon-tooltip">{t(messages.CPYB)}</div>
                    </div>
                  )}
                  {has360 && (
                    <div className="listing-card-image-360">
                      <div className="icon-tooltip">
                        {t(messages.viewCarousel360Images.image360)}
                      </div>
                    </div>
                  )}
                  {hasVideo && (
                    <div className="listing-card-image-video">
                      <div className="icon-tooltip">
                        {t(messages.viewCarouselVideos.video)}
                      </div>
                    </div>
                  )}
                  {hasLocalDelivery && (
                    <div className="listing-card-image-delivery">
                      <div className="icon-tooltip">
                        {t(messages.boatDetails.localDelivery.label)}
                      </div>
                    </div>
                  )}
                </div>
              }
              <ListingImage
                listing={listing}
                resize={resize}
                labels={labels}
                lazyLoad={this.props.isHome || itemCount > 4}
                altText={altText}
              />
            </div>
          </div>
          <div className="description">
            <div className="top">
              <div className="name">
                <h2 className={classnames({ 'manufacturer': isManufacturer })}>
                  {title}
                </h2>
              </div>
              {(enhanced || !isManufacturer) && (
                <div className="price">
                  {price}
                  {previousPrice && <div>{previousPrice}</div>}
                  {supportsMonthlyPayment && monthlyPrice && !isPriceHidden && (
                    <MonthlyPaymentTooltip
                      listing={listing}
                      teaserRate={tridentTeaserRate}
                      monthlyPrice={monthlyPrice}
                      formatMessage={t}
                    />
                  )}
                </div>
              )}
              {isManufacturer && (
                <div className="manufacturer-tag">
                  {t(messages.dealerContact.manufacturerListing)}
                </div>
              )}
              <div className="location">
                {location}
                <div>
                  {length} - {listing.year}
                </div>
              </div>
            </div>
            {mode !== ListingResult.MODES.card && (
              <div className="bottom">
                {listingType === getBoatsConstants().LISTING_ENHANCED ? (
                  <>
                    <div
                      className={classnames('dealer', {
                        'listingStyleDefault-manufacturer': isManufacturer
                      })}
                    >
                      {dealerLogo && <ImageWithErrorHandler
                        className="dealer-logo"
                        src={dealerLogo}
                        alt={this.getDealerName()}
                        noFallbackImage={true}
                      />}
                      {!isManufacturer && (
                        <div className="offered-by">
                          <span className="sold-by">
                            {t(messages.contactGroup.offeredBy) + ': '}
                          </span>
                          {seller}
                        </div>
                      )}
                    </div>
                    <div className="contact-area">
                      {hasLiveVideoTour && (
                        <div className="contact">
                          <button
                            className="live-video-tour"
                            onClick={this.handleLiveVideoTourButtonClick}
                          >
                            {this.state.breakpoint === BREAKPOINTS.mobile ? (
                              <div></div>
                            ) : (
                              t(messages.liveVideoTour)
                            )}
                          </button>
                        </div>
                      )}
                      <div className="contact">
                        <button
                          className="contact-seller"
                          {...listingAnchorParamsDefault}
                          onClick={this.handleContactButtonClick}
                        >
                          {this.state.breakpoint === BREAKPOINTS.mobile ? (
                            <div></div>
                          ) : (
                            t(messages.contactGroup.contact)
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!isManufacturer && (
                      <div className="offered-by">{seller}</div>
                    )}
                    {isManufacturer && (
                      <div className="dealer listingStyleDefault-manufacturer">
                        <ImageWithErrorHandler
                          className="dealer-logo"
                          src={dealerLogo}
                          alt={this.getDealerName()}
                          noFallbackImage={true}
                        />
                      </div>
                    )}
                    {!isManufacturer && hasLiveVideoTour && (
                      <div className="contact-area">
                        <div className="contact">
                          <button
                            className="live-video-tour"
                            onClick={this.handleLiveVideoTourButtonClick}
                          >
                            {this.state.breakpoint === BREAKPOINTS.mobile ? (
                              <div></div>
                            ) : (
                              t(messages.liveVideoTour)
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </Link>
      </li>
    );
  }
}

ListingResult.contextType = PortalConfigContext;

ListingResult.MODES = {
  card: 'card',
  row: 'row'
};

ListingResult.defaultProps = {
  isHome: false,
  mode: ListingResult.MODES.row
};

ListingResult.propTypes = {
  breakpoint: PropTypes.oneOf(Object.keys(BREAKPOINTS)),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  /** Helper functions to build listing labels */
  labels: PropTypes.shape({
    getLocation: PropTypes.func.isRequired,
    getPrice: PropTypes.func.isRequired,
    getTitle: PropTypes.func.isRequired
  }).isRequired,
  isHome: PropTypes.bool,
  /** Listing information of the boat */
  listing: listingPropTypes.isRequired,
  /** Listing type used for tracking and reporting */
  listingType: PropTypes.string,
  mode: PropTypes.oneOf(Object.keys(ListingResult.MODES)),
  /** Position within the results list */
  position: PropTypes.shape({
    position: PropTypes.number,
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  /** Analytics tracking helper  */
  tracking: PropTypes.shape({
    setProductImpression: PropTypes.func.isRequired,
    trackingInfo: PropTypes.object
  }).isRequired,
  itemCount: PropTypes.number,
  altText: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
  monthlyPrice: PropTypes.string,
  tridentTeaserRate: PropTypes.number,
  supportsMonthlyPayment: PropTypes.bool,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  })
};

export default injectIntl(ListingResult);
