import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

class Spinner extends PureComponent {
  render() {
    const { hidden = false } = this.props;
    return (
      <div role="status" className={classnames('spinner-preloader', { hidden: hidden })}></div>
    );
  }
}

Spinner.propTypes = {
  hidden: PropTypes.bool,
};

export default Spinner;
