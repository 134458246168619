import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 99999 }
};

const Modal = ({ children, contentLabel, isOpen, onRequestClose, portalClassName, injectedStyles, preventScroll }) => {
  return (
    <ReactModal
      portalClassName={portalClassName}
      contentLabel={contentLabel}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      preventScroll={preventScroll}
      style={{
        content: { ...customStyles.content, ...get(injectedStyles, 'content', {}) },
        overlay: { ...customStyles.overlay, ...get(injectedStyles, 'overlay', {}) },
      }}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.object,
  contentLabel: PropTypes.string,
  onRequestClose: PropTypes.func,
  portalClassName: PropTypes.string,
  injectedStyles: PropTypes.object,
  preventScroll: PropTypes.bool,
};

export default Modal;

