import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import constant from 'lodash/constant';

export const LoadingBoatList = ({number}) => {
  let list = times(number, constant(null));
  return (
    <ol className="boat-list loading">
      {list.map((item, index) => {
        return (
          <li key={index} className="lib-card">
            <div className="inner">
              <div className="image-container">
                <div className="dummy"></div>
                <div className="image">
                  <div className="preloader"></div>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ol>
  );
};

LoadingBoatList.propTypes = {
  number: PropTypes.number
};
