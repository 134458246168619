import React, {useState} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { getAssetUrl } from '../../utils/commonHelper';

const ImageWithErrorHandler = ({src, alt, className, defaultSrc, noFallbackImage = false, ...props }) => {
  const [error, setError] = useState(false);
  if (!error) {
    return (
      <LazyLoadImage { ...props } className={ className } src={ src } alt={ alt } onError={ () => setError(true) } />
    );
  }

  if (noFallbackImage) {
    return null;
  }

  const defaultSource = defaultSrc || getAssetUrl('/assets/images/default/photo_camera.svg');

  return (
    <LazyLoadImage className={ className } src={ defaultSource } alt={alt} />
  );
};

ImageWithErrorHandler.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  defaultSrc: PropTypes.string,
  noFallbackImage: PropTypes.bool
};

export default ImageWithErrorHandler;
