import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import validate from '../../utils/dataValidation';

import * as leadHelper from '../../utils/api/leadHelper';
import { PortalConfigContext } from '../../config/portal';
import { getLeadTypes, getBoatLeadTypeOptions } from '../../utils/leadsHelper';
import { trackContactFormSubmit } from '../../store/actions/dataLayer';

import './style.css';
import { getMessages } from '../../tppServices/translations/messages';

const defaultState = {
  fields: {
    name: '',
    email: '',
    phone: ''
  },
  validations: {},
  showErrorMessage: false,
  showError: false,
  showSuccess: false
};

class LiveVideoTourModal extends PureComponent {
  state = defaultState;

  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
    this.inputs = this.getInputs(this.translationMessages);
  }

  getInputs = (messages) => {
    return [
      {
        name: 'name',
        placeholder: messages.liveVideoTourForm.fullName,
        validationErrorMessage: messages.liveVideoTourForm.fullNameError,
        validation: value => validate(value, 'fullName')
      },
      {
        name: 'email',
        placeholder: messages.liveVideoTourForm.email,
        validationErrorMessage: messages.liveVideoTourForm.emailError,
        validation: value => validate(value, 'email')
      },
      {
        name: 'phone',
        placeholder: messages.liveVideoTourForm.phone,
        validationErrorMessage: messages.liveVideoTourForm.phoneError,
        validation: value => value.length === 0 || validate(value, 'phoneNumber')
      }
    ];
  };

  onChange(field, value) {
    const input = find(this.inputs, ['name', field]);
    const newState = cloneDeep(this.state);
    newState.fields[field] = value;
    newState.validations[field] = input.validation(value);
    newState.showErrorMessage = false;
    this.setState(newState);
  }

  isValidForm() {
    const isAllDataValid = this.inputs
      .every(({ name, validation }) => validation(this.state.fields[name]));
    this.setState({ ...this.state, showErrorMessage: !isAllDataValid });
    return isAllDataValid;
  }

  async onSubmit(event) {
    event.preventDefault();
    const messages = this.translationMessages;
    if (this.isValidForm()) {
      const { listingId, leadType, softLeadType } = this.props;
      window.digitalDataBuilder.addLead(listingId, softLeadType);

      const leadData = {
        id: listingId,
        source: get(this.context, 'ads.leadSource'),
        type: leadType,
        formFields: {
          ...this.state.fields,
          comments: this.props.intl.formatMessage(messages.liveVideoTourForm.defaultComment)
        }
      };
      try {
        const resp = await leadHelper.sendLead(leadData);
        if (resp.status === 200) {
          this.setState({ ...defaultState, showSuccess: true });
          this.props.trackContactFormSubmit();
        } else {
          this.setState({ ...defaultState, showError: true });
        }
      } catch (error) {
        this.setState({ ...defaultState, showError: true });
      }
    }
  }

  renderInputs(t) {
    return this.inputs.map(({ name, placeholder, validationErrorMessage }, index) =>
      <fieldset key={`live-video-tour-form-fieldset-${name}`}>
        <input
          placeholder={t(placeholder)}
          key={`live-video-tour-form-${name}`}
          id={name} name={name}
          type="text" tabIndex={index + 1}
          onChange={event => this.onChange(name, event.target.value)}
        />
        {this.state.validations[name] === false && validationErrorMessage &&
          <span className="message-error">{t(validationErrorMessage)}</span>}
      </fieldset>
    );
  }

  renderForm(t) {
    const messages = this.translationMessages;
    const {
      errorMessage, title, text, sendButton
    } = messages.liveVideoTourForm;

    return (<div className="main-form">
      <div className="title">{t(title)}</div>
      <div className="subtitle">{t(text)}</div>
      {this.state.showErrorMessage && <div className="message-error">{t(errorMessage)}</div>}
      <form onSubmit={(e) => this.onSubmit(e)}>
        <input type="hidden" id="listing-id" name="listing-id" value="" />
        <input type="hidden" id="imt-id" name="imt-id" value="" />
        <input type="hidden" id="zipCode" name="zipCode" value="" />

        {this.renderInputs(t)}

        <div className="submit-container">
          <button type="submit" className="btn-action" tabIndex="4">{t(sendButton)}</button>
        </div>
      </form>
    </div>);
  }

  renderSuccessMessage(t) {
    const messages = this.translationMessages;
    const {
      successMessageText, successTitle
    } = messages.liveVideoTourForm;

    return (<div className="message-sent">
      <div className="success-alert">
        <div className="message-box">
          <p className="message-title">{t(successTitle)}</p>
          <p>{t(successMessageText)}</p>
        </div>
      </div>
    </div>);
  }

  renderErrorMessage(t) {
    const messages = this.translationMessages;
    const {
      errorMessageText, errorTitle
    } = messages.liveVideoTourForm;
    return (<div className="message-sent-error">
      <div className="error-alert">
        <div className="message-box">
          <p className="message-title">{t(errorTitle)}</p>
          <p>{t(errorMessageText)}</p>
        </div>
      </div>
    </div>);
  }

  render() {
    const { intl: { formatMessage: t } } = this.props;

    let component = this.renderForm(t);
    if (this.state.showSuccess === true) {
      component = this.renderSuccessMessage(t);
    } else if (this.state.showError === true) {
      component = this.renderErrorMessage(t);
    }

    return (
      <div className="video-tour-form-container">
        <div id="video-tour-form">
          <button className="icon-x contact-close" onClick={this.props.onClose}>
            <span className="aria-fixes">X</span>
          </button>
          {component}
        </div>
      </div>
    );
  }
}

LiveVideoTourModal.propTypes = {
  leadType: PropTypes.oneOf(getLeadTypes()),
  softLeadType: PropTypes.string,
  listingId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  trackContactFormSubmit: PropTypes.func
};

LiveVideoTourModal.defaultProps = {
  leadType: getBoatLeadTypeOptions().type,
  softLeadType: 'video tour submit'
};

LiveVideoTourModal.contextType = PortalConfigContext;

export default connect(
  null,
  (dispatch) => bindActionCreators({
    trackContactFormSubmit
  }, dispatch)
)(injectIntl(LiveVideoTourModal));
