import React from 'react';
import {FormattedMessage} from 'react-intl';
import get from 'lodash/get';
import range from 'lodash/range';
import {
  capitalizeEachWord,
  formatNumber
} from '@dmm/lib-common/lib/formatting';
import {
  getFacetTranslation,
  translateMake,
  capitalizeString,
  unhyphenateUrlComponents
} from './commonHelper';
import { getLocale } from './uomHelper';
import { getActiveParamsLength } from './urlHelpers/boats';
import {
  isFilteringOnlyByCity,
  isFilteringOnlyBySubdivision,
  isFilteringOnlyByRegion,
  isFilteringOnlyByCountry
} from './locationHelper';
import {getBoatConstantsFromI18n} from '../tppServices/translations/constants';
import {unslugify} from './urlHelpers/shared';
import { syncTranslationElements } from '../tppServices/translations/intlManager';

export const getNumberOfPages = (numberOfListings, params) => {
  const pageSize = parseInt(get(params, 'pageSize', '28'));
  return parseInt(numberOfListings / pageSize) + 1;
};

const makesReservedWords = ['yachts', 'yacht', 'boats', 'boat'];

export const getResultsCount = (
  params,
  totalItems,
  locale,
  seoMakeInfo = {},
  enginesPage = false,
  brand,
  i18nService
) => {
  const { formatMessage: t, messages } = syncTranslationElements(i18nService);
  let {
    makeModel = {},
    multiFacetedBoatTypeClass,
    condition,
    country,
    region,
    subdivision,
    city
  } = params;

  let { seoMakeName } = seoMakeInfo;
  let boatClass,
    make,
    model,
    translatedCity,
    translatedCondition,
    translatedCountry;
  let translatedRegion, translatedSubdivision, type;
  let messageBase = enginesPage
    ? messages.searchHeader.viewing.engines
    : messages.searchHeader.viewing.listings;
  let formattedMessage = messageBase.combined;

  if (country && isFilteringOnlyByCountry(params)) {
    formattedMessage = messageBase.country;
    translatedCountry = getFacetTranslation(`countries[${country}]`);
  }
  if (region && isFilteringOnlyByRegion(params)) {
    formattedMessage = messageBase.region;
    translatedRegion = getFacetTranslation(`countryRegions[${region}]`);
  }
  if (subdivision && isFilteringOnlyBySubdivision(params)) {
    formattedMessage = messageBase.subdivision;
    translatedSubdivision = getFacetTranslation(
      `countrySubdivision.${country}.${subdivision}`
    );
  }
  if (Array.isArray(city) && city.length > 0 && isFilteringOnlyByCity(params)) {
    formattedMessage = messageBase.city;
    translatedCity = capitalizeString(unhyphenateUrlComponents(city[0]));
  }

  if (condition && condition !== 'any') {
    formattedMessage = messageBase.condition;
    translatedCondition = getFacetTranslation(
      `searchPageSEO.indexableFacets.condition[${condition}]`
    ).toLowerCase();
  }

  // Filter only by one of class, make, model or type
  if (getActiveParamsLength(params) === 1) {
    type =
      multiFacetedBoatTypeClass &&
      get(Object.keys(multiFacetedBoatTypeClass), '0', '');
    boatClass = get(multiFacetedBoatTypeClass, `${type}.0`, '');
    const boatConstants = getBoatConstantsFromI18n(i18nService);
    boatClass = boatConstants.allTypes.includes(boatClass) ? undefined : boatClass;

    // Class takes precedence over type
    if (boatClass) {
      boatClass =
        boatClass &&
        getFacetTranslation(`classFacetValues[${boatClass}]`).toLowerCase();
      formattedMessage = messageBase.class;
    } else if (type) {
      type = type && getFacetTranslation(type);
      formattedMessage = messageBase.type;
    }

    make = get(Object.keys(makeModel), '0', '');
    model = get(makeModel, `${make}.0`, '');
    make = capitalizeEachWord(
      unhyphenateUrlComponents(unslugify(translateMake(make)))
    );
    // Model takes precedence over make
    if (model) {
      model = capitalizeEachWord(unhyphenateUrlComponents(unslugify(model)));
      formattedMessage = messageBase.model;
    } else if (make) {
      if (seoMakeName) {
        make = seoMakeName;
      }
      if (
        makesReservedWords.some((substring) =>
          make.toLowerCase().includes(substring)
        )
      ) {
        formattedMessage = messageBase.make.norepeat;
      } else {
        formattedMessage = messageBase.make;
      }
    }
  }

  if (getActiveParamsLength(params) === 0) {
    formattedMessage = messageBase;
  }

  if (brand) {
    return `${totalItems} ${brand} Models`;
  }

  locale = getLocale();
  return totalItems ? (
    <FormattedMessage
      {...formattedMessage}
      values={{
        total: formatNumber(totalItems, locale),
        class: boatClass,
        make,
        model,
        type,
        condition: translatedCondition,
        country: translatedCountry,
        region: translatedRegion,
        subdivision: translatedSubdivision,
        city: translatedCity
      }}
    />
  ) : (
    t(messages.searchHeader.viewing.noListings)
  );
};

export const getPaginationData = (
  params,
  totalItems,
  maxPages,
  visiblePages
) => {
  let { page, pageSize } = params;
  page = +page;
  pageSize = +pageSize;
  const searchMaxBoats = pageSize * maxPages;
  totalItems = Math.min(searchMaxBoats, totalItems);
  let pages =
    Math.ceil(totalItems / pageSize) < maxPages
      ? Math.ceil(totalItems / pageSize)
      : maxPages;
  let toPage;
  let fromPage;
  let delta;

  if (visiblePages >= pages) {
    fromPage = 1;
    toPage = pages;
  } else {
    delta = Math.floor(visiblePages / 2);
    if (page - delta < 1) {
      fromPage = 1;
      toPage = visiblePages;
    } else {
      if (page + delta >= pages) {
        toPage = pages;
        fromPage = pages - visiblePages + 1;
      } else {
        toPage = page + delta;
        fromPage = page - delta;
      }
    }
  }

  let response = {
    page: page,
    pageSize: pageSize,
    isFirstPage: page === 1,
    isLastPage: page === pages || totalItems <= pageSize,
    visibleNumbers: range(fromPage, toPage + 1)
  };

  return response;
};

export const setPosition = (pos, i) => {
  return { position: (pos.page - 1) * pos.pageSize + i, page: pos.page };
};
