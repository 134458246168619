import React, { useRef, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Modal from '../Modal';


import { isSafelyInitializedWithLayout } from '../../utils/commonHelper';
import { isFinanceable } from '../../utils/trident';

import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';
import { getBoatLoanConstants } from '../../constants/BoatLoans';
import { getConfig } from '../../config/portal';

export const MonthlyPaymentTooltip = ({ listing, teaserRate, monthlyPrice, formatMessage: t }) => {
  const [tipOpen, setTipOpen] = useState(false);
  const buttonRef = useRef(null);
  const tipContentRef = useRef(null);
  const messages = getMessages();
  const boatLoansConstants = getBoatLoanConstants(getConfig());
  const { PAYMENT_MODAL_DESKTOP, PAYMENT_MODAL_MOBILE, PAYMENT_INFO_ICON } = boatLoansConstants;

  const hiddenPrice = get(listing, 'price.hidden') || !listing.price?.type?.amount || listing.price?.type?.amount.USD <= 1;
  const price = get(listing, 'price.type.amount.USD');
  const term = price && price >= 50000 ? 240 : 180;
  const isDesktop = isSafelyInitializedWithLayout('desktop');
  const closeImg = isDesktop ? PAYMENT_MODAL_DESKTOP : PAYMENT_MODAL_MOBILE;
  const isPremium = get(listing, 'featureType.enhanced', false) ? 'enhanced' : '';
  let direction =  isDesktop ? 'down-end' : 'down-start';
  const content = messages.boatLoansContent.monthlyPaymentTooltip;
  const boatYear = get(listing, 'year');
  const showMonthlyPayment = !hiddenPrice && monthlyPrice && isFinanceable(price, boatYear);

  const toggleTip = (event) => {
    event.preventDefault();
    setTipOpen(prevState => !prevState);
  };

  return showMonthlyPayment ? (
    <>
      <div onClick={toggleTip}>
        <Modal
          isOpen={tipOpen}
          portalClassName="tooltip-modal-actionable"
          contentLabel="Tooltip modal"
          preventScroll
        />
      </div>
      <Tooltip
        direction={direction}
        className={`tooltip-wrapper ${direction} ${isPremium}`}
        isOpen={tipOpen}
        forceDirection={true}
        content={(
          <div ref={tipContentRef} className="tooltip-monthly">
            <div className="tooltip-monthly-head">
              <span className="tooltip-monthly-head-monthlyPayment" data-testid="tooltip-monthly-payment" onClick={toggleTip}>{`$${monthlyPrice}/${t(content.month)}`}</span>
              <span className="tooltip-monthly-head-close" onClick={toggleTip} >
                <img alt="tooltip close button" src={closeImg} />
              </span>
            </div>
            <div className="tooltip-monthly-body" onClick={toggleTip}>
              <p className="tooltip-monthly-body-paragraph">{t(content.text, {term, teaserRate})}</p>
            </div>
          </div>
        )}
      >
        <div
          ref={buttonRef}
          onClick={toggleTip}
          className="monthly-price"
          data-testid="monthly-payment-link"
        >
          {`${t(content.currencyCode)} $${monthlyPrice}/${t(content.monthAbbreviation)}`}{!isDesktop ? '*' : ''}
          { isDesktop &&
            <span className="info-icon"><img alt="tooltip info icon" src={`${PAYMENT_INFO_ICON}`} /></span>
          }
        </div>
      </Tooltip>
    </>
  ) : null;
};

MonthlyPaymentTooltip.propTypes = {
  listing: PropTypes.object.isRequired,
  teaserRate: PropTypes.number.isRequired,
  formatMessage: PropTypes.func.isRequired,
  monthlyPrice: PropTypes.string,
};

export default MonthlyPaymentTooltip;
