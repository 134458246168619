import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Link from '../../../components/SEO/Link';
import { PortalConfigContext } from '../../../config/portal';

import { getAssetUrl } from '../../../utils/commonHelper';
import './styles.css';
import { getPortalName } from '../../../utils/language';
import { getMessages } from '../../../tppServices/translations/messages';

class FSBOAd extends PureComponent {
  render() {
    const {
      intl: { formatMessage: t },
      url
    } = this.props;
    const messages = getMessages();
    const portal = getPortalName(this.context);
    return (
      <div className="main-fsbo-ad">
        <div className="left">
          <div className="title">{t(messages.fsboAd.fsboTitle)}</div>
          <div className="fsbo-text">
            {t(messages.fsboAd.fsboMessage, { portal })}
          </div>
          <div className="fsbo-link">
            <Link
              href={url}
              data-reporting-fsbo-internal-link-url="/home"
              data-reporting-fsbo-internal-link-id="fsbo banner"
            >
              {t(messages.fsboAd.button)}
            </Link>
          </div>
        </div>
        <div className="right">
          <div
            loading="lazy"
            style={{
              backgroundImage: `url(${getAssetUrl(
                '/assets/home/fsbo/boat.webp'
              )})`
            }}
            alt={t(messages.fsboAd.alt)}
            className="fsbo-image"
          />
        </div>
      </div>
    );
  }
}

FSBOAd.propTypes = {
  /** Translation items */
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  /** Url for FSBO site */
  url: PropTypes.string.isRequired
};

FSBOAd.contextType = PortalConfigContext;
export default injectIntl(FSBOAd);
