import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setListingClick } from '../../store/actions/dataLayer';
import { getLocaleUrl } from '../../utils/urlHelpers/boat';
import {useTPPServices} from '../../tppServices/tppDIHooks';
import { addResizeParams, addNotModifiedResizeParams } from '../../utils/listingHelper';

const FEATURED = 'featured listing';

const FeaturedBoatCard = ({
  provisioningName,
  title,
  logoUrl,
  price,
  photoUrl,
  mediaModified,
  listingUrl,
  location,
  id,
  externalLink,
  tracking,
  featuredLabel = 'featured',
  position = 1
}) => {
  const {tpp} = useTPPServices();

  useEffect(() => {
    const page = tracking?.trackingInfo?.page;
    const region = tracking?.trackingInfo?.region;
    // eslint bug: https://github.com/facebook/create-react-app/issues/8107
    // eslint-disable-next-line no-unused-expressions
    tracking?.setProductImpression(
      id,
      FEATURED,
      position,
      page,
      null,
      null,
      region,
      null,
      title
    );
  }, []);

  const linkProps = externalLink
    ? { target: '_blank', rel: 'noopener nofollow' }
    : {};
  const logoUrlHttps = logoUrl?.replace('http://', 'https://').replace('boatsgroup.com/images/', 'boatsgroup.com/resize/');
  const linkURL = externalLink ? listingUrl : getLocaleUrl(listingUrl, tpp);

  const src = addResizeParams(photoUrl, { width: 550, height: 250 }, mediaModified, true, 'webp');

  const srcset = `
    ${addResizeParams(photoUrl, { width: 400, height: 250 }, mediaModified, true, 'webp')} 400w,
    ${addResizeParams(photoUrl, { width: 500, height: 250 }, mediaModified, true, 'webp')} 500w,
    ${addResizeParams(photoUrl, { width: 600, height: 250 }, mediaModified, true, 'webp')} 600w,
    ${addResizeParams(photoUrl, { width: 720, height: 250 }, mediaModified, true, 'webp')} 720w
  `;

  const sizes = `
    (max-width: 450px) 400px,
    (max-width: 550px) 500px,
    (max-width: 650px) 600px,
    (max-width: 767px) 720px,
    (max-width: 868px) 400px,
    (max-width: 1023px) 500px,
    (min-width: 1024px) 400px
  `;

  return (
    <div
      id={`featured-${id}`}
      className="featured-boat"
      data-listing-id={id}
      data-reporting-impression-product-id={id}
      data-reporting-impression-listing-type={FEATURED}
      onClick={() => setListingClick(id, FEATURED, location, title)}
    >
      <a href={linkURL} {...linkProps}>
        <div className="fb-gradient">
          <div className="fb-logo">
            <img src={addNotModifiedResizeParams(logoUrlHttps, {width: 80, height: 40}, false)} alt={title} loading="lazy" />
          </div>
          <div className="fb-content">
            <p className="fb-featured">{featuredLabel}</p>
            <p className="fb-title">{title}</p>
            {price && <p className="fb-price">{price}</p>}
            <p className="fb-location">{provisioningName + ' | ' + location}</p>
          </div>
        </div>
        <img className="fb-card-image" srcSet={ srcset } sizes={ sizes } src={ src } alt={ title } loading="lazy" />
      </a>
    </div>
  );
};

FeaturedBoatCard.propTypes = {
  title: PropTypes.string,
  photoUrl: PropTypes.string,
  mediaModified: PropTypes.string,
  logoUrl: PropTypes.string,
  price: PropTypes.string,
  location: PropTypes.string,
  listingUrl: PropTypes.string,
  externalLink: PropTypes.bool,
  featuredLabel: PropTypes.string,
  id: PropTypes.number,
  position: PropTypes.number,
  tracking: PropTypes.object,
  provisioningName: PropTypes.string
};

export default FeaturedBoatCard;
