import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BREAKPOINTS } from '../../../utils/commonHelper';

import { editContentAnchors } from '../utils/utils';
import { PortalConfigContext } from '../../../config/portal';
import { setCollapseContentTracking } from '../../../store/actions/dataLayer';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleHeader
} from '../../../components/Collapsible';
import EditorialItem from '../../../components/EditorialItem';
import './styles.css';
import {useIntl} from 'react-intl';
import { getMessages } from '../../../tppServices/translations/messages';

const QuickLinks = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const messages = getMessages();
  const context = useContext(PortalConfigContext);
  const parseEditorialItem = (content) => {
    return editContentAnchors(content, context.pages.home.seoConfig);
  };

  const renderQuickLinksRedesign = (content) => {
    return (
      <div className={classnames('home-quicklinks-container')}>
        <h2>{t(messages.quickLinks.popular)}</h2>
        {content.map((list, index) => {
          const headerNumber = props.headerNumber || 2;
          return (
            <CollapsibleContent initialState="closed" key={index}>
              <CollapsibleHeader
                id={list.id}
                priority={headerNumber}
                onClick={(e, open) => {
                  if (open) {
                    setCollapseContentTracking(list.id);
                  }
                }}
              >
                {list.title}
              </CollapsibleHeader>
              <Collapsible type="no-pad">
                <EditorialItem
                  content={parseEditorialItem(list.html)}
                  key={index}
                  classOverride={'quicklink-list'}
                />
              </Collapsible>
            </CollapsibleContent>
          );
        })}
      </div>
    );
  };

  const { content, breakpoint, quickLinksRedesign } = props;
  if (quickLinksRedesign) {
    return renderQuickLinksRedesign(content);
  }
  return (
    <div className={classnames('homepage-quicklinks-container')}>
      {content.map((list, index) => {
        if (breakpoint === BREAKPOINTS.mobile) {
          return (
            <CollapsibleContent
              initialState="closed"
              classOverride="details"
              key={index}
            >
              <CollapsibleHeader
                id={list.id}
                onClick={(e, open) => {
                  if (open) {
                    setCollapseContentTracking(list.id);
                  }
                }}
              >
                {list.title}
              </CollapsibleHeader>
              <Collapsible type="no-pad">
                <EditorialItem
                  content={parseEditorialItem(list.html)}
                  key={index}
                  classOverride={'quicklink-list'}
                />
              </Collapsible>
            </CollapsibleContent>
          );
        }
        return (
          <EditorialItem
            header={list.title}
            content={parseEditorialItem(list.html)}
            key={index}
            classOverride={'quicklink-list'}
          />
        );
      })}
    </div>
  );
};

QuickLinks.propTypes = {
  /** The content to be displayed */
  content: PropTypes.array.isRequired,
  /** breakpoint for display */
  breakpoint: PropTypes.string.isRequired,
  /** Flag to enable the links redesign */
  quickLinksRedesign: PropTypes.bool,
  headerNumber: PropTypes.number
};

export default QuickLinks;
