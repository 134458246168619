import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { getConfig } from '../../config/portal';

const Meta = ({
  noindex,
  nofollow,
  canonical,
  next,
  prev,
  title,
  description
}) => {
  let canonicalOrNoIndex;
  if (noindex && nofollow) {
    canonicalOrNoIndex = <meta name="robots" content="noindex, nofollow" />;
  } else if (noindex || getConfig().supports?.noIndex) {
    canonicalOrNoIndex = <meta name="robots" content="noindex" />;
  } else {
    canonicalOrNoIndex = <link rel="canonical" href={canonical} />;
  }

  const linkRelPrev = next ? <link rel="prev" href={next} /> : '';
  const linkRelNext = prev ? <link rel="next" href={prev} /> : '';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {canonicalOrNoIndex}
        {linkRelPrev}
        {linkRelNext}
      </Helmet>
    </>
  );
};

Meta.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  next: PropTypes.string,
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
  prev: PropTypes.string,
  title: PropTypes.string
};

export default Meta;
