
import get from 'lodash/get';
import { getConfig } from '../../../config/portal';
import { syncTranslationElements } from '../../../tppServices/translations/intlManager';

export const getMetaTitle = (portal) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return t(get(messages, 'homePageSEO.metaElements.title'), { portal });
};

export const getMetaDescription = (portal) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return t(get(messages, 'homePageSEO.metaElements.description'), { portal });
};

export const getCanonical = () => {
  return get(getConfig(), 'host') + '/';
};
