import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const HomeBuyingExperience = (props) => {
  const {
    categories,
    intl: { formatMessage: t }
  } = props;
  const messages = getMessages();
  return (
    <div className="buying-experience">
      <h3>{t(messages.homeBuyingSection.title)}</h3>
      <div className="features">
        {categories.map(({ classIcon, name }) => {
          const urlHomeBuyingSection = t(
            get(messages, `homeBuyingSection.${name}.url`)
          );
          if (urlHomeBuyingSection === '/') {
            return null;
          }
          return (
            <a
              href={urlHomeBuyingSection}
              key={name}
              className="experience-feature"
            >
              <div className={`icon ${classIcon}`} />
              <div className="excerpt">
                <h4>{t(get(messages, `homeBuyingSection.${name}.title`))}</h4>
                <p>{t(get(messages, `homeBuyingSection.${name}.excerpt`))}</p>
                <div className="learn-more">
                  {t(messages.homeBuyingSection.more)}
                  <div className="learn-more-arrow" />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

HomeBuyingExperience.propTypes = {
  categories: PropTypes.array,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default connect(null, (dispatch) => bindActionCreators({}, dispatch))(
  injectIntl(HomeBuyingExperience)
);
