export const adsConfig = {
  'div-gpt-ad-box-1': {
    breakpoint: {
      min: 768,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [[300, 250]],
    targeting: {
      pos: 'box-1'
    }
  },
  'div-gpt-ad-box-2': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [[300, 250]],
    targeting: {
      pos: 'box-2'
    }
  },
  'div-gpt-ad-mobile-box-1': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [[300, 250]],
    targeting: {
      pos: 'mobile-box-1'
    }
  },
  'div-gpt-ad-mobile-box-2': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [[300, 250]],
    targeting: {
      pos: 'mobile-box-2'
    }
  },
  'div-gpt-ad-mobile-box-3': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [[300, 250]],
    targeting: {
      pos: 'mobile-box-3'
    }
  },
  'div-gpt-ad-leaderboard-mid-1': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [[728, 90]],
    targeting: {
      pos: 'leaderboard-mid-1'
    }
  },
  'div-gpt-ad-leaderboard-mid-2': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [[728, 90]],
    targeting: {
      pos: 'leaderboard-mid-2'
    }
  },
  'div-gpt-ad-leaderboard-bottom': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728, 90],
      [970, 90],
      [970, 250]
    ],
    targeting: {
      pos: 'leaderboard-bottom'
    }
  },
  'div-gpt-ad-PFBA': {
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [[1440, 500]],
    targeting: {
      pos: 'hero'
    },
    prebid: {
      bids: []
    }
  }
};

export const getTargeting = (site) => {
  let targeting = {
    page: 'home',
    site
  };

  return targeting;
};
