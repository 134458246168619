import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Dropdown from '../Dropdown';
import { Option, OptionSet } from '../OptionSet';
import Input from '../Input';
import InputAutocomplete from '../InputAutocomplete';
import { DEBOUNCE_TIMEOUT_SHORT } from '../../constants';
import { hyphenateUrlComponents } from '../../utils/urlHelpers/boats';
import {
  isMobileResolution
} from '../../utils/commonHelper';

import classnames from 'classnames';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';

const QuickSearchFilters = (props) => {
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const [makeModelText, setMakeModelText] = useState('');
  const [validMakeModelCondition, setValidMakeModelCondition] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);

  useEffect(() => {
    if (!suggestionSelected) {
      executeAutoComplete();
    }
  }, [makeModelText]);

  const executeAutoComplete = debounce(() => {
    const { onMakeModelInputChange } = props.actions;
    if (makeModelText.length >= 3) {
      onMakeModelInputChange(makeModelText);
    }
  }, DEBOUNCE_TIMEOUT_SHORT);

  const getMakeModelOptions = (makesAndModels) => {
    const options = [];

    makesAndModels.makes &&
      makesAndModels.makes.forEach((make) => {
        const makeKey = hyphenateUrlComponents(make.key);

        options.push({
          key: { [makeKey]: [] },
          style: 'autocomplete-make-item',
          value: make.key,
          label: make.key
        });
      });
    return options;
  };

  const onMakeModelSuggestionClick = (element) => {
    const { onFilterMakeModelChange } = props.actions;
    onFilterMakeModelChange(element.key);
    let makeModelText = element.value;
    setSuggestionSelected(true);
    setMakeModelText(makeModelText);
  };

  const onMakeModelTextChange = (makeModelText) => {
    const { onFilterMakeModelChange } = props.actions;
    setMakeModelText(makeModelText);
    setSuggestionSelected(false);
    executeAutoComplete();
    makeModelText === '' && onFilterMakeModelChange(makeModelText);
  };

  const onErrorUpdateValidation = (err) => {
    setValidMakeModelCondition(!err);
  };

  const updateResolution = () => setIsMobile(isMobileResolution());

  useEffect(() => {
    window.addEventListener('resize', updateResolution);
    updateResolution();
    return () => {
      window.removeEventListener('resize', updateResolution);
    };
  }, []);

  const redirectToSrp = () => {
    const { filterCategory } = props.filterValues;
    const routeConstants = getRouteConstantsFromI18n();
    const { SEARCH_URL_ROOT, ENGINES_SEARCH_URL_ROOT } = routeConstants;

    if (filterCategory === 'engines') {
      window.location.href = ENGINES_SEARCH_URL_ROOT;
    } else {
      window.location.href = SEARCH_URL_ROOT;
    }
  };

  const {
    onQuickCategoryChange,
    searchPath,
    setHomeSearchTracking,
    onQuickAttributeChanged,
    onQuickUomChange
  } = props.actions;

  const {
    bottomRowClasses,
    categories,
    filterCategory,
    conditions,
    regions,
    makesAndModels,
    uoms
  } = props.filterValues;

  const {
    hideCategoryInput,
    hideMakeModelInput,
    hideLengthInput,
    hideMinYearInput,
    hideAllCategory,
    hideUnpoweredCategory,
    hideEngineCategory,
    hideLocationInput,
    hideCondition,
    disabledClass,
    hideUomSelector
  } = props.layoutFlags;

  const { messages, values } = props;
  const isEngineCategory = values.filterCategory === 'engines';
  const t = props.intl.formatMessage;

  const makeModelOptions = getMakeModelOptions(makesAndModels);
  const shouldBeVisible = !isMobile || (isMobile && !isCollapse);

  return (
    <div id="quick-search-container" className="quick-search-container">
      <h1>{t(messages.quickSearch.title)}</h1>
      <div className="quick-search">
        <div className="top-row">
          <div className="quick-search-category-selectors-container">
            {!hideAllCategory && (
              <label
                htmlFor="all"
                className={filterCategory === 'all' ? 'qs-checked' : ''}
              >
                <input
                  id="all"
                  type="radio"
                  checked={filterCategory === 'all'}
                  value="all"
                  name="category"
                  onChange={() => onQuickCategoryChange('all')}
                />
                {t(messages.searchCategory.allMotors)}
              </label>
            )}

            <label
              htmlFor="power"
              className={filterCategory === 'power' ? 'qs-checked' : ''}
            >
              <input
                id="power"
                type="radio"
                checked={filterCategory === 'power'}
                value="power"
                name="category"
                onChange={() => onQuickCategoryChange('power')}
              />
              {t(messages.searchCategory.motorBoats)}
            </label>

            <label
              htmlFor="sail"
              className={filterCategory === 'sail' ? 'qs-checked' : ''}
            >
              <input
                id="sail"
                type="radio"
                checked={filterCategory === 'sail'}
                value="sail"
                name="category"
                onChange={() => onQuickCategoryChange('sail')}
              />
              {t(messages.searchCategory.sailboats)}
            </label>

            {!hideUnpoweredCategory && (
              <label
                htmlFor="unpowered"
                className={filterCategory === 'unpowered' ? 'qs-checked' : ''}
              >
                <input
                  id="unpowered"
                  type="radio"
                  checked={filterCategory === 'unpowered'}
                  value="unpowered"
                  name="category"
                  onChange={() => onQuickCategoryChange('unpowered')}
                />
                {t(messages.searchCategory.unpowered)}
              </label>
            )}

            {!hideEngineCategory && (
              <label
                htmlFor="engines"
                className={filterCategory === 'engines' ? 'qs-checked' : ''}
              >
                <input
                  id="engines"
                  type="radio"
                  checked={filterCategory === 'engines'}
                  value="engines"
                  name="category"
                  onChange={() => onQuickCategoryChange('engines')}
                />
                {t(messages.searchCategory.engines)}
              </label>
            )}
          </div>
          {!isMobile && (
            <span
              onClick={() => redirectToSrp()}
              className="qs-category-advanced-search"
            >
              {t(messages.searchCategory.advancedSearch)}
            </span>
          )}
        </div>
        <form
          id="quick-search-form"
          className={bottomRowClasses}
          action={searchPath()}
          autoComplete="off"
        >
          {!hideCondition && (
            <Dropdown
              className="input-condition"
              label={t(messages.condition)}
              setValue={(value) =>
                onQuickAttributeChanged({ condition: value })
              }
              value={values.condition}
              options={conditions}
              render={(options, inheritProps) => (
                <OptionSet {...inheritProps}>
                  {options.map((option, idx) => (
                    <Option
                      key={'condition-' + idx}
                      option={option}
                      {...inheritProps}
                    >
                      {option.value}
                    </Option>
                  ))}
                </OptionSet>
              )}
            />
          )}
          {!isEngineCategory && !hideCategoryInput && (
            <Dropdown
              className="input-class"
              label={t(messages.category)}
              filter
              setValue={(value) =>
                onQuickAttributeChanged({
                  category: value,
                  makeModel: {},
                  location: []
                })
              }
              value={values.category}
              options={categories}
              optionKey="label"
              disabled={disabledClass}
              render={(options, inheritProps) => (
                <OptionSet {...inheritProps}>
                  {options.map((option, idx) => (
                    <Option
                      key={'category-' + idx}
                      option={option}
                      {...inheritProps}
                    >
                      {option.label}
                    </Option>
                  ))}
                </OptionSet>
              )}
            />
          )}
          {!isEngineCategory && !hideMakeModelInput && (
            <InputAutocomplete
              className="input-makemodel"
              label={t(messages.make)}
              setValue={onMakeModelTextChange}
              value={makeModelText}
              options={makeModelOptions}
              onOptionClick={onMakeModelSuggestionClick}
              onErrorUpdate={onErrorUpdateValidation}
              message={t(messages.quickSearch.makeModelErrorMessage)}
              defaultDropdownMessage={t(messages.quickSearch.makeModelDropdown)}
            />
          )}

          {!isEngineCategory && !hideLengthInput && shouldBeVisible && (
            <div className="length">
              <Input
                id="min-length-input"
                label={t(messages.searchLength)}
                placeholder={t(messages.quickSearch.min)}
                setValue={(value) =>
                  onQuickAttributeChanged({ minLength: value })
                }
                value={values.minLength}
                min={0}
                type={'number'}
                className={'area-range min'}
              />
              <Input
                id="max-length-input"
                name=""
                label={t(messages.searchLength)}
                placeholder={t(messages.quickSearch.max)}
                setValue={(value) =>
                  onQuickAttributeChanged({ maxLength: value })
                }
                value={values.maxLength}
                min={0}
                type={'number'}
                className={`area-range${hideUomSelector ? '' : ' max'}`}
              />
              {!hideUomSelector && (
                <div className="qs-uoms">
                  {uoms.length > 1 &&
                    uoms.map((uom, index) => (
                      <div
                        className={classnames({
                          active: uom.isSelected,
                          left: index === 0,
                          right: index > 0
                        })}
                        onClick={() => onQuickUomChange(uom.symbol)}
                        key={`uom-${uom.symbol}`}
                      >
                        {t(messages.quickSearch.uoms[uom.symbol])}
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}

          {!hideMinYearInput && (shouldBeVisible || isEngineCategory) && (
            <div className="year">
              <Input
                id="min-year-input"
                label={t(messages.year)}
                placeholder={t(messages.quickSearch.min)}
                setValue={(value) =>
                  onQuickAttributeChanged({ minYear: value })
                }
                value={values.minYear}
                min={0}
                type={'number'}
                className={'area-range min'}
              />
              <Input
                id="max-year-input"
                label={t(messages.year)}
                placeholder={t(messages.quickSearch.max)}
                setValue={(value) =>
                  onQuickAttributeChanged({ maxYear: value })
                }
                value={values.maxYear}
                min={0}
                type={'number'}
                className={'area-range'}
              />
            </div>
          )}

          {(shouldBeVisible || isEngineCategory) && (
            <div className="price">
              <Input
                id="min-price-input"
                label={t(messages.price)}
                placeholder={t(messages.quickSearch.min)}
                setValue={(value) =>
                  onQuickAttributeChanged({ minPrice: value })
                }
                value={values.minPrice}
                min={0}
                type={'number'}
                className={'area-range min'}
              />
              <Input
                id="max-price-input"
                label={t(messages.price)}
                placeholder={t(messages.quickSearch.max)}
                setValue={(value) =>
                  onQuickAttributeChanged({ maxPrice: value })
                }
                value={values.maxPrice}
                min={0}
                type={'number'}
                className={'area-range'}
              />
            </div>
          )}

          {!hideLocationInput && (
            <Dropdown
              className="location"
              label={t(messages.location)}
              filter
              setValue={(value) => onQuickAttributeChanged({ location: value })}
              value={values.location}
              options={regions}
              translateOption={(options) =>
                t(messages.countryRegions[options[0].value])
              }
              render={(options, inheritProps) => (
                <OptionSet {...inheritProps}>
                  {options.map((option, idx) => (
                    <Option
                      key={'region-' + idx}
                      {...inheritProps}
                      option={option}
                    >
                      {t(messages.countryRegions[option.value])}
                    </Option>
                  ))}
                </OptionSet>
              )}
            ></Dropdown>
          )}
          {isMobile && !isEngineCategory && (
            <label
              onClick={() => setIsCollapse(!isCollapse)}
              className="qs-category-show"
            >
              {isCollapse
                ? t(messages.quickSearch.showMore)
                : t(messages.quickSearch.showLess)}
              <span
                className={classnames('icon', {
                  collapse: !isCollapse
                })}
              ></span>
            </label>
          )}
          <button
            id="button-search"
            disabled={!validMakeModelCondition}
            type="submit"
            className="button-search"
            onClick={() => {
              setHomeSearchTracking();
            }}
          >
            {t(messages.searchTitle)}
          </button>
          {isMobile && (
            <label
              onClick={() => redirectToSrp()}
              className="qs-category-advanced-search"
            >
              {t(messages.searchCategory.advancedSearch)}
            </label>
          )}
        </form>
      </div>
    </div>
  );
};

QuickSearchFilters.propTypes = {
  actions: PropTypes.shape({
    onFilterMakeModelChange: PropTypes.func,
    onMakeModelInputChange: PropTypes.func,
    onQuickAttributeChanged: PropTypes.func,
    onQuickCategoryChange: PropTypes.func,
    searchPath: PropTypes.func,
    setHomeSearchTracking: PropTypes.func,
    onQuickUomChange: PropTypes.func
  }),
  messages: PropTypes.object,
  filterValues: PropTypes.object,
  layoutFlags: PropTypes.object,
  values: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default injectIntl(memo(QuickSearchFilters));
