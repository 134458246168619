import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Ad } from '@dmm/react-common-components';
import '../styles.css';

class MobileLinerAd extends Component {
  render() {
    let { adNum } = this.props;

    const mobileAdOneParams = {
      adId: 'div-gpt-ad-mobile-box-' + adNum,
      classes: 'ad ad-inline-mobile-box'
    };

    return (
      <li className="mobile-liner-ad premium bp1 ad-320x80">
        <Ad {...mobileAdOneParams} />
      </li>
    );
  }
}

MobileLinerAd.propTypes = {
  adNum: PropTypes.number.isRequired
};

export default MobileLinerAd;
