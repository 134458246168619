import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Ad } from '@dmm/react-common-components';
import '../styles.css';

class LeaderboardMid extends Component {
  render() {
    let { adNum } = this.props;

    const leaderboardMidParams = {
      adId: 'div-gpt-ad-leaderboard-mid-' + adNum,
      classes: 'ad w728'
    };

    return (
      <li className="desktop-liner-ad">
        <Ad {...leaderboardMidParams} />
      </li>
    );
  }
}

LeaderboardMid.propTypes = {
  adNum: PropTypes.number.isRequired
};

export default LeaderboardMid;
