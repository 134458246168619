import React from 'react';
import PropTypes from 'prop-types';
import {asArray} from '../../utils';
import FeaturedBoatCard from './FeaturedBoatCard';
import classNames from 'classnames';
import './style.css';

const FeaturedBoats = ({tracking, featuredBoats = [], featuredLabel = '',  wrapperClass = ''}) => {
  if (asArray(featuredBoats).length === 0) {
    return null;
  }

  return (
    <div id="featured_boats" className={classNames(wrapperClass)}>
      <div className={classNames('fb-yachts', 'main-content-element')}>
        <h2 className="fb-label">{featuredLabel}</h2>
        <div className="fb-cards">
          {featuredBoats.map((fb, i) => (<FeaturedBoatCard key={i} tracking={tracking} position={i + 1} {...fb} />))}
        </div>
      </div>

    </div>
  );
};

FeaturedBoats.propTypes = {
  featuredBoats: PropTypes.array,
  featuredLabel: PropTypes.string,
  wrapperClass: PropTypes.string,
  tracking: PropTypes.object
};

export default FeaturedBoats;
