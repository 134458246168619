import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isOuterClick } from '../../utils';
import './styles.css';
import './details.css';

const CollapsibleContent = ({
  children,
  classOverride,
  closeOnInnerClick,
  closeOnOuterClick,
  initialState,
  onOpen,
  open: externalOpen,
  openCounter
}) => {
  const [open, setOpen] = useState(initialState === 'open');
  const containerRef = useRef();

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (!closeOnOuterClick) {
        return;
      }
      const outerClicked = isOuterClick(e.target, containerRef.current);
      if (outerClicked) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [closeOnOuterClick]);

  useEffect(() => {
    if (
      externalOpen === 'open' &&
      open !== 'open' &&
      openCounter !== externalOpen
    ) {
      setOpen(externalOpen);
      if (onOpen) {
        onOpen();
      }
    }
  }, [externalOpen, onOpen, open, openCounter]);

  const isInnerClick = (element) => {
    let target = element;
    let current = containerRef.current;
    while (target) {
      if (target.classList.contains('collapsible')) {
        return true;
      }
      if (target === current) {
        return false;
      }
      target = target.parentNode;
    }
  };

  const handleInnerClick = (e) => {
    if (!closeOnInnerClick) {
      return;
    }
    const innerClicked = isInnerClick(e.target);
    if (innerClicked) {
      setOpen(false);
    }
  };

  const handleToggle = (e, child) => {
    if (child.props.onClick) {
      child.props.onClick(e, !open);
    }
    setOpen(!open);
    if (!open && onOpen) {
      onOpen();
    }
  };

  let mainClassName = 'collapse-content';
  if (classOverride) {
    mainClassName += `-${classOverride}`;
  }

  return (
    <div
      id="collapse-content"
      ref={containerRef}
      className={classnames(mainClassName, { open, closed: !open })}
      onClick={handleInnerClick}
    >
      {React.Children.map(children, (child) => {
        if (child.type.displayName === 'CollapsibleHeader') {
          return React.cloneElement(child, {
            onClick: (e) => handleToggle(e, child)
          });
        }
        return child;
      })}
    </div>
  );
};

CollapsibleContent.propTypes = {
  /** Requires a CollapsibleHeader and Collapsible to work  */
  children: PropTypes.node,
  /** Appends the override at the end of the class ex: collapse-content-? */
  classOverride: PropTypes.string,
  /** Close on click inside the element */
  closeOnInnerClick: PropTypes.bool,
  /** Close on click outside the element */
  closeOnOuterClick: PropTypes.bool,
  /** open | close  */
  initialState: PropTypes.string,
  /** Callback when the collapsable is opened */
  onOpen: PropTypes.func,
  /** Remote controls open|close */
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Required increasing number if using open prop for external open, else only work once*/
  openCounter: PropTypes.number
};

const CollapsibleHeader = ({
  children,
  type,
  empty,
  onClick,
  priority = 2
}) => {
  const Header = priority === 0 ? 'span' : `h${priority}`;
  return (
    <div className={classnames('header', { [type]: type, empty })}>
      {empty ? (
        children
      ) : (
        <Header
          id="collapse-header"
          onClick={onClick}
          className={`${type || 'collapse'}-header`}
        >
          <a className="toggle-btn">
            {children}
            <span className="icon"></span>
          </a>
        </Header>
      )}
    </div>
  );
};

CollapsibleHeader.propTypes = {
  /** Title on the header */
  children: PropTypes.node,
  /** Renders empty div rendering onClick useless */
  empty: PropTypes.bool,
  /** Callback whenever the collapsable is being click */
  onClick: PropTypes.func,
  /** Title */
  type: PropTypes.string,
  /** Heading priority (1, 2, 3, 4, 5, 6) */
  priority: PropTypes.number
};

CollapsibleHeader.displayName = 'CollapsibleHeader';

const Collapsible = ({ children, type, doubleHeight, extraClass = '' }) => {
  return (
    <div
      className={classnames(
        'collapsible',
        { 'double-height': doubleHeight },
        { [type]: type },
        { [extraClass]: extraClass }
      )}
    >
      {children}
    </div>
  );
};

Collapsible.propTypes = {
  /** Text content for example */
  children: PropTypes.node,
  /** If present sets an extra class: no-pad | internal */
  type: PropTypes.string,
  /** Enables double height class */
  doubleHeight: PropTypes.bool,
  /** Appends extra class */
  extraClass: PropTypes.string,
};

export { CollapsibleContent, CollapsibleHeader, Collapsible };
