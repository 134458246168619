import React, { PureComponent } from 'react';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './styles.css';
import { formatMultiFacetParam } from '../../utils/multiFacetHelper';
import { getMessages } from '../../tppServices/translations/messages';


class SimilarListingsMessage extends PureComponent {


  render () {
    const { intl: { formatMessage: t }, params, similarListingsType } = this.props;
    const messages = getMessages();
    let makeModel;
    let paragraph1, paragraph2;
    switch (similarListingsType){
    case 'city':
      paragraph1 = t(messages.nearbyListings.paragraph1, { city: upperFirst(params.city) });
      paragraph2 = t(messages.nearbyListings.paragraph2);
      break;
    case 'model':
      makeModel = formatMultiFacetParam(params.multiFacetedMakeModel);
      paragraph1 = t(messages.similarModelBoats.paragraph1,
        { make: upperFirst(makeModel[0][0]), model: makeModel[0][1][0].replace(/-/g, ' ') });
      paragraph2 = t(messages.similarModelBoats.paragraph2);
      break;
    }
    return (
      <div className="nearby-listings-message-container">
        <div className="nearby-listings-message-arrow"></div>
        <div className="nearby-listings-message-text">
          <p><b>{ paragraph1 }</b></p>
          <p>{paragraph2}</p>
        </div>
      </div>
    );
  }
}

SimilarListingsMessage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.object.isRequired,
  similarListingsType: PropTypes.string.isRequired
};

export default injectIntl(SimilarListingsMessage);
