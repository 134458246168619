import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import { getBoatsConstants } from '../../constants/boats';
import { addResizeParams } from '../../utils/listingHelper';
import { listingPropTypes } from '../../utils/commonPropTypes';
import { getTitle } from '../../utils/listingHelper';
import DefaultImage from '../DefaultImage';

class ListingImage extends PureComponent {
  state = {
    imageError: false
  };

  handleImageError() {
    this.setState({
      imageError: true,
      defaultImage: true
    });
  }

  render() {
    let { listing, lazyLoad, resize, altText } = this.props;
    let listingMedia = get(listing, 'media', []);
    let images = listingMedia.filter((media) => {
      return media.mediaType === 'image';
    });
    let image = images[0];
    let title = getTitle(listing);

    const imgHeight = get(
      resize,
      'height',
      getBoatsConstants().LISTING_RESIZES.default.height
    );
    const imgWidth = get(
      resize,
      'width',
      getBoatsConstants().LISTING_RESIZES.default.width
    );
    return (
      <>
        {image && !this.state.imageError ? (
          <img
            alt={altText ? altText : title}
            className={classnames('image-results', {
              'wide': image.width > 700
            })}
            height={imgHeight}
            loading={lazyLoad ? 'lazy' : 'eager'}
            fetchpriority={lazyLoad ? 'auto' : 'high'}
            onError={this.handleImageError.bind(this)}
            src={addResizeParams(
              image.url,
              resize,
              image.date.modified,
              true,
              'webp'
            )}
            width={imgWidth}
          />
        ) : (
          <DefaultImage size="70" />
        )}
      </>
    );
  }
}

ListingImage.defaultProps = {
  lazyLoad: false,
  listing: {},
  resize: {}
};

ListingImage.propTypes = {
  /** Make image visible by default */
  lazyLoad: PropTypes.bool,
  /** Listing information of the boat */
  listing: listingPropTypes,
  /** Resize specifications for the image */
  resize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }),
  altText: PropTypes.string
};

export default ListingImage;
