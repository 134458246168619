import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'react-cookie/cjs/Cookies';
import get from 'lodash/get';
import classnames from 'classnames';
import { generateSearchPath } from '../../utils/urlHelpers/boats';
import { generateSearchPath as generateEngineSearchPath } from '../../utils/urlHelpers/engines';

import { getFacets, getMakeModelTypeahead } from '../../store/actions';
import { setHomeSearchTracking } from '../../store/actions/dataLayer';
import { bindActionCreators } from 'redux';
import { getRegions } from '../../utils/multiFacetHelper';
import { PortalConfigContext } from '../../config/portal';
import { getLength as getUomAbbr } from '../../utils/uomHelper.js';
import './styles.css';
import './qs-alt-styles.css';
import QuickSearchLayout from './QuickSearchLayout';
import { setCookie } from '../../utils/cookies.js';
import { UOM_COOKIE } from '../../constants/cookies/index.js';
import { getMessages } from '../../tppServices/translations/messages';

const QuickSearch = (props) => {
  const context = useContext(PortalConfigContext);
  const [filterCategory, setFilterCategory] = useState(
    get(context, 'pages.home.hidden.quicksearch.allCategory', false)
      ? 'power'
      : 'all'
  );
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [location, setLocation] = useState([]);
  const [makeModel, setMakeModel] = useState({});
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minLength, setMinLength] = useState('');
  const [maxLength, setMaxLength] = useState('');
  const [minYear, setMinYear] = useState('');
  const [maxYear, setMaxYear] = useState('');
  const [condition, setCondition] = useState([]);
  const [uom, setUom] = useState('');
  const messages = getMessages();

  const componentStates = {
    filterCategory,
    categories,
    category,
    location,
    makeModel,
    minPrice,
    maxPrice,
    minLength,
    maxLength,
    minYear,
    maxYear,
    condition,
    uom
  };
  const stateMappings = {
    minLength: setMinLength,
    maxLength: setMaxLength,
    minYear: setMinYear,
    maxYear: setMaxYear,
    minPrice: setMinPrice,
    maxPrice: setMaxPrice,
    location: setLocation,
    condition: setCondition,
    category: setCategory,
    makeModel: setMakeModel
  };

  useEffect(() => {
    setCategories(filterCategories(filterCategory));
    setUom(getUomAbbr(props.customUom?.length, context));
  }, []);

  useEffect(() => {
    setCategories(filterCategories(filterCategory));
  }, [props.facets, filterCategory]);

  /** Filter class by selected category
   * @param {string} filter
   */
  const filterCategories = (filter) => {
    const {
      facets,
      intl: { formatMessage: t }
    } = props;
    let types = [filter];
    let shouldSetTitle = false;
    if (filter === 'all') {
      types = Object.keys(context.boatTypes);
      shouldSetTitle = true;
    }

    const start = types.reduce((labels, type) => {
      if (messages.quickSearch[`${type}All`]) {
        labels.push({
          value: `${type}-all`,
          label: t(messages.quickSearch[`${type}All`])
        });
      }
      return labels;
    }, []);

    let categories = types.reduce((result, boatType) => {
      let facetsByType = get(facets, 'class', []).reduce(
        (accumulator, element) => {
          if (
            get(context.boatTypes, boatType, []).includes(element.value) &&
            !!messages.classFacetValues[element.value]
          ) {
            accumulator.push({
              ...element,
              label: t(messages.classFacetValues[element.value])
            });
          }
          return accumulator;
        },
        []
      );
      facetsByType = facetsByType.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      if (shouldSetTitle && !!messages.quickSearch[`${boatType}Boats`]) {
        return result.concat(
          [
            {
              value: boatType,
              label: t(messages.quickSearch[`${boatType}Boats`]),
              isTitle: true
            }
          ],
          facetsByType
        );
      }
      return result.concat(facetsByType);
    }, start);

    return categories;
  };

  /** Build class payload for generateSearchPath
   * also used to request filtered facets
   * @param {array} categories
   */
  const getClass = (categories) => {
    const categoriesByType = categories.reduce((result, item) => {
      const type = item.value.split('-')[0];
      result[type] = result[type] || [];
      result[type].push(item.value);
      return result;
    }, {});

    if (!Object.keys(categoriesByType).length && filterCategory !== 'all') {
      categoriesByType[filterCategory] = [];
    }

    return categoriesByType;
  };

  /** Build Search */
  const getParams = () => {
    let params = {
      makeModel: makeModel,
      multiFacetedBoatTypeClass: getClass(category),
      price: {},
      length: {},
      year: {},
      condition: ''
    };

    if (minPrice) {
      params.price.min = Math.trunc(minPrice);
    }
    if (maxPrice) {
      params.price.max = Math.trunc(maxPrice);
    }
    if (minLength) {
      params.length.min = Math.trunc(minLength);
    }
    if (maxLength) {
      params.length.max = Math.trunc(maxLength);
    }
    if (minYear) {
      params.year.min = minYear;
    }
    if (maxYear) {
      params.year.max = maxYear;
    }

    if (location.length && !location[0].default) {
      params.region = location[0].value;
    }
    if (condition.length) {
      const conditionValues = condition[0];
      if (conditionValues.filterName) {
        params.condition = conditionValues.filterName;
      }
    }
    return params;
  };

  const searchPath = () => {
    if (filterCategory === 'engines') {
      return generateEngineSearchPath(getParams());
    }
    return generateSearchPath(getParams());
  };

  /** Reset the selected values of category and make each time filter is updated
   * @param {string} newFilter
   */
  const onFilterCategoryChange = (newFilter) => {
    setFilterCategory(newFilter);
    setCondition([]);
    setCategory([]);
    setMakeModel({});
    setMinLength('');
    setMaxLength('');
  };

  const onFilterMakeModelChange = (makeModel) => {
    setMakeModel(makeModel);
  };

  const onQuickAttributeChange = (newState) => {
    Object.entries(newState).forEach(([key, value]) => {
      const setStateFunction = stateMappings[key];
      setStateFunction(value);
    });
  };

  const onUomChange = (uom) => {
    const inOneDay = new Date();
    inOneDay.setDate(inOneDay.getDate() + 1);

    const data = {
      value: 1,
      expires: inOneDay,
      path: '/'
    };
    setCookie(props.cookies, UOM_COOKIE, uom, data);
    setUom(uom);
  };

  const getQuickSearchProps = () => {
    const {
      facets,
      intl: { formatMessage: t },
      customUom
    } = props;
    const lengthAbbr = getUomAbbr(customUom?.length, context);

    const lengths = {
      m: [
        { value: `0 - 5${lengthAbbr}.`, min: 0, max: 5 },
        { value: `5 - 10${lengthAbbr}.`, min: 5, max: 10 },
        { value: `0 - 5${lengthAbbr}.`, min: 0, max: 5 },
        { value: `5 - 10${lengthAbbr}.`, min: 5, max: 10 },
        { value: `10 - 15${lengthAbbr}.`, min: 10, max: 15 },
        { value: `15 - 20${lengthAbbr}.`, min: 15, max: 20 },
        { value: `20 - 25${lengthAbbr}.`, min: 20, max: 25 },
        { value: `25 - 30${lengthAbbr}.`, min: 25, max: 30 },
        { value: `30${lengthAbbr}.+`, min: 30 }
      ],
      ft: [
        { value: `0 - 16${lengthAbbr}.`, min: 0, max: 16 },
        { value: `16 - 33${lengthAbbr}.`, min: 16, max: 33 },
        { value: `0 - 16${lengthAbbr}.`, min: 0, max: 16 },
        { value: `16 - 33${lengthAbbr}.`, min: 16, max: 33 },
        { value: `33 - 49${lengthAbbr}.`, min: 33, max: 49 },
        { value: `49 - 66${lengthAbbr}.`, min: 49, max: 66 },
        { value: `66 - 82${lengthAbbr}.`, min: 66, max: 82 },
        { value: `82 - 98${lengthAbbr}.`, min: 82, max: 98 },
        { value: `98${lengthAbbr}.+`, min: 98 }
      ]
    };
    const uoms = [
      { symbol: 'ft', isSelected: uom === 'ft' },
      { symbol: 'm', isSelected: uom === 'm' }
    ];

    const regions = [
      { value: 'all-regions', default: true },
      ...getRegions(facets, context.country).sort((a, b) =>
        a.value.localeCompare(b.value)
      )
    ];

    const conditions = [
      { value: t(messages.all), filterName: '', default: true },
      {
        value: t(messages.new),
        filterName: messages.new.defaultMessage.toLowerCase()
      },
      {
        value: t(messages.used),
        filterName: messages.used.defaultMessage.toLowerCase()
      }
    ];

    const makesAndModels = props.makeModelSuggestions;
    const hideLocationInput = get(
      context,
      'pages.home.hidden.quicksearch.location',
      false
    );
    const hideMakeModelInput = get(
      context,
      'pages.home.hidden.quicksearch.makeModel',
      false
    );
    const hideCategoryInput = get(
      context,
      'pages.home.hidden.quicksearch.category',
      false
    );
    const hideMinYearInput = get(
      context,
      'pages.home.hidden.quicksearch.minYear',
      false
    );
    const hideLengthInput = get(
      context,
      'pages.home.hidden.quicksearch.length',
      false
    );
    const hideUnpoweredCategory = get(
      context,
      'pages.home.hidden.quicksearch.unpoweredCategory',
      false
    );
    const hideEngineCategory = get(
      context,
      'pages.home.hidden.quicksearch.engineCategory',
      false
    );
    const bottomRowClasses = classnames('bottom-row', {
      'bottom-row__no-location': hideLocationInput,
      'engines': filterCategory === 'engines'
    });
    const hideCondition = get(
      context,
      'pages.home.hidden.quicksearch.condition',
      false
    );
    const hideAllCategory = get(
      context,
      'pages.home.hidden.quicksearch.allCategory',
      false
    );
    const disabledClass =
      Object.keys(makeModel).length > 0 && (!category || !category.length);
    const supportedUoms = get(context, 'supportedUoms');
    const hideUomSelector = !supportedUoms.ft || !supportedUoms.m;
    const filterValues = {
      filterCategory,
      bottomRowClasses,
      categories,
      lengths: lengths[lengthAbbr],
      regions,
      conditions,
      makesAndModels,
      uoms
    };
    const layoutFlags = {
      hideUnpoweredCategory,
      hideEngineCategory,
      hideCategoryInput,
      hideMakeModelInput,
      hideLengthInput,
      hideMinYearInput,
      hideLocationInput,
      hideAllCategory,
      hideCondition,
      disabledClass,
      hideUomSelector
    };

    return {
      messages,
      filterValues,
      layoutFlags
    };
  };

  const overlapLayout = !props.quickSearchBlock;
  const quickSearchProps = getQuickSearchProps();
  const { layoutFlags, filterValues } = quickSearchProps;
  const quickSearchActions = {
    onQuickCategoryChange: (f) => onFilterCategoryChange(f),
    searchPath: () => searchPath(),
    setHomeSearchTracking: () => props.setHomeSearchTracking(getParams()),
    onQuickAttributeChanged: (newState) => onQuickAttributeChange(newState),
    onFilterMakeModelChange: (makeModel) => onFilterMakeModelChange(makeModel),
    onMakeModelInputChange: (text) =>
      props.getMakeModelTypeahead(
        text,
        category.map((item) => `class=${item.value.replace('-all', '')}`)
      ),
    onQuickUomChange: (uom) => onUomChange(uom)
  };

  return (
    <Route
      render={() => {
        return (
          <QuickSearchLayout
            messages={messages}
            values={componentStates}
            filterValues={filterValues}
            actions={quickSearchActions}
            layoutFlags={layoutFlags}
            overlapLayout={overlapLayout}
            adParams={props.adParams}
          />
        );
      }}
    />
  );
};

QuickSearch.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  facets: PropTypes.object,
  getFacets: PropTypes.func.isRequired,
  setHomeSearchTracking: PropTypes.func.isRequired,
  quickSearchBlock: PropTypes.bool,
  adParams: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  makeModelSuggestions: PropTypes.object,
  getMakeModelTypeahead: PropTypes.func,
  cookies: PropTypes.instanceOf(Cookies).isRequired
};

const mapStateToProps = (state) => {
  return {
    facets: get(state.app, 'data.facets', {}),
    makeModelSuggestions: get(state.app, 'makeModel.makeModels', {})
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      getFacets,
      setHomeSearchTracking,
      getMakeModelTypeahead
    },
    dispatch
  )
)(injectIntl(memo(QuickSearch)));
