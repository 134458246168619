import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import Spinner from '../Spinner';
import ListingResult from '../ListingResult';
import { setPosition } from '../../utils/paginationHelper';
import { getLocation, getPrice, getTitle } from '../../utils/listingHelper';
import { carouselButtonClick } from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//To Be used when tracking is added
// import { clickCarouselThumbnail } from '../../store/actions/dataLayer';
import { setProductImpression } from '../../store/actions/dataLayer';
import {
  MAX_CARD_THUMBNAIL_WIDTH,
  CAROUSEL_CARDS_NUMBER,
} from '../../constants/boatDetails';
import './styles.css';

class ListingCardItem extends PureComponent {
  render() {
    const { id, media } = this.props.itemCard;
    this.props.itemCard.media = !media ? [] : media;
    const position = setPosition(
      { page: '1', pageSize: `${16}` },
      this.props.index + 1
    );
    return (
      <>
        {
          <ListingResult
            key={id}
            isHome={this.props.isHome}
            listing={this.props.itemCard}
            position={position}
            tracking={this.props.tracking}
            labels={{
              getTitle: getTitle,
              getPrice: getPrice,
              getLocation: getLocation,
            }}
            itemCount={this.props.index}
            listingType="featured private listing"
            mode={ListingResult.MODES.card}
          />
        }
      </>
    );
  }
}

ListingCardItem.propTypes = {
  /** function that will be called on click a thubnail */
  itemCard: PropTypes.object,
  index: PropTypes.number,
  tracking: PropTypes.shape({
    setProductImpression: PropTypes.func.isRequired,
  }).isRequired,
  isHome: PropTypes.bool
};

class ListingCarousel extends PureComponent {
  state = {
    indexCardClicked: null,
    index: 0,
    controlledPosition: {
      x: 0,
      y: 0,
    },
    showPrevArrow: false,
    showNextArrow: true,
  };

  selectorRef = React.createRef(null);

  calculateMaxIndex = () => {
    return Math.ceil(this.props.cardItems.length / CAROUSEL_CARDS_NUMBER[this.props.device]) - 1;
  }

  handleThumbnailClick = (event, key) => {
    // this.props.clickCarouselThumbnail();
    this.setState({
      indexCardClicked: key,
    });
  };

  handleTouchStart = () => {
    this.setState({
      touchScrolled: false,
    });
  };

  handleTouchMove = () => {
    this.setState({
      touchScrolled: true,
    });
  };

  handleTouchEnd = (event, key) => {
    if (this.state.touchScrolled === false) {
      this.handleThumbnailClick(event, key);
    }
  };

  handlePrevArrow = () => {
    const { x, y } = this.state.controlledPosition;
    this.setState((prevState) => {
      return {
        index: Math.max(prevState.index - 1, 0),
        controlledPosition: {
          x:
            prevState.index === this.calculateMaxIndex()
              ? x +
              (this.state.totalPixelsLength -
                prevState.index * this.state.maxPixelsToMove)
              : Math.min(x + this.state.maxPixelsToMove, 0),
          y,
        },
        showPrevArrow: prevState.index > 1,
        showNextArrow: prevState.index <= this.calculateMaxIndex(),
      };
    });

    this.props.carouselButtonClick();
  };

  handleNextArrow = () => {
    const { x, y } = this.state.controlledPosition;
    this.setState((prevState) => {
      return {
        index: Math.min(prevState.index + 1, this.calculateMaxIndex()),
        controlledPosition: {
          x: Math.max(
            x - this.state.maxPixelsToMove,
            this.state.maxIndexPixels
          ),
          y,
        },
        showPrevArrow: prevState.index >= 0,
        showNextArrow: prevState.index < this.calculateMaxIndex() - 1,
      };
    });

    this.props.carouselButtonClick();
  };

  onControlledDrag = (e, position) => {
    const { x } = position;
    this.setState({
      controlledPosition: {
        x,
        y: 0,
      },
    });
  };

  updateDimensions = () => {
    let maxPixelsToMove = this.props.showComponent
      ? this.selectorRef.current?.offsetWidth
      : Math.min(window.innerWidth, MAX_CARD_THUMBNAIL_WIDTH);

    this.setState({
      maxPixelsToMove: maxPixelsToMove,
      totalPixelsLength:
        (maxPixelsToMove / CAROUSEL_CARDS_NUMBER[this.props.device]) * this.props.cardItems.length,
      maxIndexPixels:
        -1 *
        ((this.props.cardItems.length / CAROUSEL_CARDS_NUMBER[this.props.device]) *
          maxPixelsToMove -
          maxPixelsToMove),
    });
  };

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  };

  componentDidUpdate = () => {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  };

  renderLoading() {
    return <Spinner />;
  }

  render() {
    let { cardItems, selectedItem, isLoading } = this.props;
    let { controlledPosition } = this.state;

    return (
      <>
        {isLoading ? (
          this.renderLoading()
        ) : (
          <div className="thumbs-wrapper" ref={this.selectorRef}>
            <div className="carousel-thumbs">
              <Draggable
                onStart={() => this.props.device !== 'desktop'}
                axis="x"
                bounds={{ left: this.state.maxIndexPixels, right: 0 }}
                position={controlledPosition}
                onDrag={this.onControlledDrag}
                cancel=".carousel-thumbs__img-wrapper"
              >
                <div className="carousel-thumbs__wrapper">
                  {cardItems.length !== 0 &&
                    cardItems.map((item, key) => {
                      return (
                        <div
                          className={classnames(
                            'carousel-thumbs__img-wrapper',
                            { selected: selectedItem === key }
                          )}
                          key={key}
                          onClick={(event) =>
                            this.handleThumbnailClick(event, key)
                          }
                          onTouchStart={() => this.handleTouchStart()}
                          onTouchMove={() => this.handleTouchMove()}
                          onTouchEnd={(event) =>
                            this.handleTouchEnd(event, key)
                          }
                        >
                          <ListingCardItem
                            index={key}
                            isHome={true}
                            itemCard={item}
                            tracking={{ setProductImpression: this.props.setProductImpression }}
                          />
                        </div>
                      );
                    })}
                </div>
              </Draggable>
            </div>
            {cardItems.length > CAROUSEL_CARDS_NUMBER[this.props.device] && (
              <div
                onClick={this.handlePrevArrow}
                className={classnames('control-arrow', 'control-prev', {
                  hide: !this.state.showPrevArrow,
                })}
              ></div>
            )}
            {cardItems.length > CAROUSEL_CARDS_NUMBER[this.props.device] && (
              <div
                onClick={this.handleNextArrow}
                className={classnames('control-arrow', 'control-next', {
                  hide: !this.state.showNextArrow,
                })}
              ></div>
            )}
          </div>
        )}
      </>
    );
  }
}

ListingCarousel.defaultProps = {
  selectedItem: 0,
  showComponent: true,
  lazyLoadListingImages: true
};

ListingCarousel.propTypes = {
  /** Defines if the component data is loading */
  isLoading: PropTypes.bool,
  /** The kind of device: mobile, tablet, desktop */
  device: PropTypes.string,
  /** Array of the items that will be shon in as thumbnails */
  cardItems: PropTypes.array,
  /** the selected item in the carousel */
  selectedItem: PropTypes.number,
  /** tracking function */
  clickCarouselThumbnail: PropTypes.func,
  /** function that will be called on click a thubnail */
  // handleThumbClick: PropTypes.func,
  /** function that will be called on click a next/prev arrow */
  carouselButtonClick: PropTypes.func,
  setProductImpression: PropTypes.func.isRequired,
  lazyLoadListingImages: PropTypes.bool,
  showComponent: PropTypes.bool
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression,
      /* clickCarouselThumbnail,*/
      carouselButtonClick,
    },
    dispatch
  )
)(ListingCarousel);
