import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

class Input extends PureComponent {
  render() {
    const {
      autoComplete,
      error, message, label,
      max, min, placeholder,
      setValue, success,
      type, value, className
    } = this.props;
    const id = this.props.id || label.toLowerCase().replace(' ', '-') + '-input';

    return (
      <div className={
        classnames('input-field-container', {
          [className]: Boolean(className)
        })
      }>
        {label && <label
          className={
            classnames('input-field-label',
              {
                'input-field-label-success': success,
                'input-field-label-error': error,
              })
          }
          htmlFor={id}
        >
          {label}
        </label>}
        <div
          className={
            classnames('input-field-input-container', {
              'input-field-input-container-success': success,
              'input-field-input-container-error': error,
            })
          }
        >
          <input
            className={
              classnames('input-field-input', {
                'input-field-input-active': value,
                'input-field-input-success': success,
                'input-field-input-error': error,
              })
            }
            id={id}
            value={value}
            max={max}
            min={min}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder || label}
            type={type}
            autoComplete={autoComplete ? 'on' : 'off'}
          />
        </div>
        <span
          className={
            classnames('input-field-message',
              {
                'input-field-message-error': error,
              })
          }
        >{message}</span>
      </div>
    );
  }
}

Input.defaultProps = {
  autoComplete: true,
  error: false,
  message: '',
  success: false,
  type: 'text',
  max: '',
  min: '',
  className: ''
};

Input.propTypes = {
  autoComplete: PropTypes.bool,
  /** Error flag if true will render border red and the x mark*/
  error: PropTypes.bool,
  /** Input Label & Placeholder if it is not defined */
  label: PropTypes.string,
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  /** message if not empty will render and inherits color if error */
  message: PropTypes.string,
  /** Placeholder if not set label will be used instead */
  placeholder: PropTypes.string,
  /** Callback function  will call when value is updated */
  setValue: PropTypes.func.isRequired,
  /** If true will render the border green and show the ✓ mark */
  success: PropTypes.bool,
  /** Input type */
  type: PropTypes.string,
  /** The current value  */
  value: PropTypes.string.isRequired,
  /** custom class one may want to specify to the componet*/
  className: PropTypes.string,
  /** id for input */
  id: PropTypes.string
};

export default Input;
