import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { getMessages } from '../../tppServices/translations/messages';

const HomeForumSection = (props) => {
  const {
    intl: { formatMessage: t }
  } = props;
  const messages = getMessages();
  return (
    <div className="forum-banner" id="forum-banner">
      <div className="forum-banner-elements">
        <div className="forum-title">{t(messages.homeForumSection.title)}</div>
        <a href={t(messages.homeForumSection.link)} className="forum-button">
          <div className="forum-button-container">
            <div className="forum-button-text">
              {t(messages.homeForumSection.button)}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

HomeForumSection.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default connect(null, (dispatch) => bindActionCreators({}, dispatch))(
  injectIntl(HomeForumSection)
);
